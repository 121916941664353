import {
    CREATE_SLIDER_REQUEST,
    CREATE_SLIDER_SUCCESS,
    CREATE_SLIDER_FAIL,
    GET_SLIDER_REQUEST,
    GET_SLIDER_SUCCESS,
    GET_SLIDER_FAIL,
    DELETE_SLIDER_REQUEST,
    DELETE_SLIDER_SUCCESS,
    DELETE_SLIDER_FAIL,
    CLEAR_ERRORS,
    RESET_NEW,
    RESET_DELETE,
} from '../constants/sliderConstant';

export const slidersReducer = (state = { sliders: [] }, action) => {
    switch (action.type) {
        case GET_SLIDER_REQUEST:
            return {
                loading: true,
                sliders: []
            };

        case GET_SLIDER_SUCCESS:
            return {
                ...state,
                loading: false,
                sliders: action.payload.data.data
            };

        case GET_SLIDER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};

export const newSliderReducer = (state = { slider: {} }, action) => {
    switch (action.type) {
        case CREATE_SLIDER_REQUEST:
            return {
                loading: true,
            };

        case CREATE_SLIDER_SUCCESS:
            return {
                ...state,
                loading: false,
                success: action.payload.data.success,
                message: action.payload.data.message
            };

        case CREATE_SLIDER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        case RESET_NEW:
            return {
                loading: false
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};

export const sliderReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_SLIDER_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case DELETE_SLIDER_SUCCESS:
            return {
                ...state,
                loading: false,
                isDeleted: action.payload.data.success,
                message: action.payload.data.message
            };

        case DELETE_SLIDER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case RESET_DELETE:
            return {
                loading: false,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};