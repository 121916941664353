import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import Loader from '../../components/loader/Loader';
import NotFound from '../../components/notFound/NotFound';
import { getJob } from '../../state/actions/jobAction';

const Career = () => {
    const dispatch = useDispatch();

    const { loading, jobs } = useSelector(state => state.jobs);

    useEffect(() => {
        dispatch(getJob());
    }, [dispatch]);
    return (
        <>
            {loading ? <Loader /> : <></>}
            <Breadcrumb title="Studymate Career" />
            <section className="studymate-job-vacancy-section">
                <div className="container">
                    {
                        jobs && jobs.filter(opt => opt.visibility === "visible").length <= 0
                            ?
                            <NotFound message="No vacancy available!" />
                            :
                            <>
                                <div className="row">
                                    {
                                        jobs && jobs.filter(opt => opt.visibility === "visible").map((data, index) => (
                                            <div className="col-md-10 m-auto" key={index}>
                                                <div className="job-parent-wrapper">
                                                    <div className="job-childs-wrapper">
                                                        <div className="vacancy-image-wrapper">
                                                            <img src={data && data.image} alt="studymate_vacancy" />
                                                        </div>
                                                    </div>
                                                    <div className="job-childs-wrapper">
                                                        <div className="job-info-wrapper">
                                                            <p>{data && data.designation}</p>
                                                            <h3>{data && data.title}</h3>
                                                            <span>{data && data.jobtype} | {data && data.location}</span>
                                                            <div className="apply-btns">
                                                                <Link to={`/careerDetail/${data.id}`}>View Details</Link>
                                                                <Link to={`/careerApplication/${data.id}`}>Apply Now</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </>
                    }
                </div>
            </section>
        </>
    )
}

export default Career