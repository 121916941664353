import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { loaduser } from './state/actions/userAction';
import { Route, Routes } from 'react-router-dom';
import Login from './pages/auth/Login';
import TopNavigation from './components/layout/TopNavigation';
import Navigation from './components/layout/Navigation';
import Home from './pages/views/Home';
import Footer from './components/layout/Footer';
import Success from './pages/views/successMessage/Success';
import Application from './pages/views/Application';
import SuccessMessage from './pages/views/successMessage/SuccessMessage';
import Contact from './pages/views/Contact';
import AboutSub from './pages/views/AboutSub';
import ServiceDetail from './pages/views/ServiceDetail';
import ImageGallery from './pages/views/Gallery';
import Career from './pages/views/Career';
import Notification from './pages/views/Notification';
import { traficManager } from './state/actions/webInfoAction';
import CareerDetail from './pages/views/career/CareerDetail';
import CareerApplication from './pages/views/career/CareerApplication';
import Branch from './pages/views/branch/Branch';

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loaduser());
    dispatch(traficManager());
  }, [dispatch]);

  return (
    <>
      <TopNavigation />
      <Navigation />
      <Notification />
      <Routes>
        <Route path="/" element={<Home />} exact />
        <Route path="/login" element={<Login />} exact />
        <Route path="/success" element={<Success />} exact />
        <Route path="/successMessage" element={<SuccessMessage />} exact />
        <Route path="/application" element={<Application />} exact />
        <Route path="/contact" element={<Contact />} exact />
        <Route path="/about" element={<AboutSub />} exact />
        <Route path="/serviceDetail/:id" element={<ServiceDetail />} exact />
        <Route path="/gallery" element={<ImageGallery />} exact />
        <Route path="/career" element={<Career />} exact />
        <Route path="/careerDetail/:id" element={<CareerDetail />} exact />
        <Route path="/careerApplication/:id" element={<CareerApplication />} exact />
        <Route path="/branch" element={<Branch />} exact />
      </Routes>
      <Footer />
    </>
  )
}

export default App