import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../components/loader/Loader';
import { getMdMessage } from '../../state/actions/mdMessageAction';
import ScrollTrigger from 'react-scroll-trigger';

const MessageFromMD = () => {
    const dispatch = useDispatch();

    const { loading, mdMessage } = useSelector(state => state.mdMessage);

    const [counterOn, setCounterOn] = useState(false);

    useEffect(() => {
        dispatch(getMdMessage());
    }, [dispatch]);
    return (
        <>
            {loading ? <Loader /> : <></>}
            <section className="message-from-md-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="studymate-header">
                                <h4>MESSAGE FROM THE MANAGING DIRECTOR(M.D.)</h4>
                            </div>
                            <div className="studymate-md-message-parent">
                                <div className="md-message-childs">
                                    <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                                        <img src={mdMessage && mdMessage.image} alt="md-prev" className={counterOn ? "fadeFromLeft" : ""}/>
                                    </ScrollTrigger>
                                    <div className="studymate-md-info">
                                        <h3>{mdMessage && mdMessage.name}</h3>
                                        <h4>{mdMessage && mdMessage.designation}</h4>
                                        <p>{mdMessage && mdMessage.company_name}</p>
                                    </div>
                                </div>
                                <div className="md-message-childs">
                                    <div className="md-contact-details">
                                        <div className="md-social-media">
                                            <a href="https://www.facebook.com/greatsurya.bhandari" target="_blank" rel="noreferrer">
                                                <div className="md-social-media-wrapper">
                                                    <i className="fab fa-facebook-f"></i>
                                                </div>
                                            </a>
                                            <a href="https://www.instagram.com/greatsurya.bhandari" target="_blank" rel="noreferrer">
                                                <div className="md-social-media-wrapper">
                                                    <i className="fab fa-instagram"></i>
                                                </div>
                                            </a>
                                            <a href="https://www.linkedin.com/in/greatsuryabhandari/" target="_blank" rel="noreferrer">
                                                <div className="md-social-media-wrapper">
                                                    <i className="fab fa-linkedin"></i>
                                                </div>
                                            </a>
                                            <a href="tel:9856077527">
                                                <div className="md-social-media-wrapper">
                                                    <i className="fa fa-phone"></i>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="detail-container-only">
                                        <div dangerouslySetInnerHTML={{ __html: mdMessage && mdMessage.message }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default MessageFromMD