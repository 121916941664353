import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { passwordReducer, userReducer } from "./state/reducers/userReducer";
import { newServiceReducer, serviceDetailReducer, serviceReducer, servicesReducer } from "./state/reducers/serviceReducer";
import { mdMessageReducer, mdMsgReducer } from "./state/reducers/mdMessageReducer";
import { applicationDetailReducer, applicationReducer, applicationsReducer, newApplicationReducer } from "./state/reducers/applicationReducer";
import { contactDetailReducer, contactReducer, contactsReducer, newContactReducer } from "./state/reducers/contactReducer";
import { newSliderReducer, sliderReducer, slidersReducer } from "./state/reducers/sliderReducer";
import { galleryReducer, gallerysReducer, newGalleryReducer } from "./state/reducers/galleryReducer";
import { newPopReducer, popDetailReducer, popReducer, popsReducer } from "./state/reducers/popReducer";
import { newTeamReducer, teamDetailReducer, teamReducer, teamsReducer } from "./state/reducers/teamReducer";
import { newUploadReducer, uploadReducer, uploadsReducer } from "./state/reducers/uploadReducer";
import { newTraficReducer, webInfoReducer } from "./state/reducers/webInfoReducer";
import { jobApplicationDetailReducer, jobApplicationReducer, jobApplicationsReducer, jobDetailReducer, jobReducer, jobsReducer, newJobApplicationReducer, newJobReducer } from "./state/reducers/jobReducer";
import { frontendReducer } from "./state/reducers/frontendReducer";

const reducer = combineReducers({
    frontend: frontendReducer,
    traficManager: newTraficReducer,
    webInfo: webInfoReducer,
    users: userReducer,
    services: servicesReducer,
    newService: newServiceReducer,
    service: serviceReducer,
    serviceDetail: serviceDetailReducer,
    mdMessage: mdMessageReducer,
    mdMsg: mdMsgReducer,
    applications: applicationsReducer,
    newApplication: newApplicationReducer,
    application: applicationReducer,
    applicationDetail: applicationDetailReducer,
    contacts: contactsReducer,
    newContact: newContactReducer,
    contactDetail: contactDetailReducer,
    contact: contactReducer,
    sliders: slidersReducer,
    newSlider: newSliderReducer,
    slider: sliderReducer,
    galleries: gallerysReducer,
    newGallery: newGalleryReducer,
    gallery: galleryReducer,
    pops: popsReducer,
    newPop: newPopReducer,
    pop: popReducer,
    popDetail: popDetailReducer,
    teams: teamsReducer,
    newTeam: newTeamReducer,
    teamDetail: teamDetailReducer,
    team: teamReducer,
    uploads: uploadsReducer,
    newUpload: newUploadReducer,
    upload: uploadReducer,
    jobs: jobsReducer,
    jobDetail: jobDetailReducer,
    newJob: newJobReducer,
    job: jobReducer,
    jobApplications: jobApplicationsReducer,
    newJobApplication: newJobApplicationReducer,
    jobApplication: jobApplicationReducer,
    jobApplicationDetail: jobApplicationDetailReducer,
    security: passwordReducer,
});

const middleware = [thunk];

const store = createStore(
    reducer,
    composeWithDevTools(applyMiddleware(...middleware))
);

export default store;