import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../assets/images/studyMateLogo.png';

const Success = () => {
  return (
    <>
      <div className="success-message-wrapper">
        <div className="success-inner">
          <img src={logo} alt="logo" />
          <h6><i className="fa fa-check-circle"></i></h6>
          <h3>Application Sent Successfully</h3>
          <p>Thank You!</p>
          <p className="mb-4">We will contact you soon!</p>
          <Link to="/">Back To Site <i className="fa fa-angle-right"></i></Link>
        </div>
      </div>
    </>
  )
}

export default Success