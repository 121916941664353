import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Conformation from '../conformation/Conformation';
import Notify from '../notify/Notify';
import DashboardBreadcrumb from '../dashboardBreadcrumb/DashboardBreadcrumb';
import Loader from '../../../components/loader/Loader';
import { getJobApplication, deleteJobApplication, clearErrors, resetDelete } from '../../../state/actions/jobAction';

const JobApplicationIndex = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, jobsApplication } = useSelector(state => state.jobApplications);
  const { loading: deleteLoading, error: deleteError, isDeleted, message: deleteMessage } = useSelector(state => state.jobApplication);

  const [isMessage, setIsMessage] = useState({
    isThereMessage: false,
    messageType: "",
    message: ""
  });

  const closeMessage = () => {
    return setIsMessage({
      isThereMessage: false,
      messageType: "",
      message: ""
    });
  }

  const [confirmBox, setConfirmBox] = useState(false);
  const [cid, setCid] = useState(null);

  const proceedToConfirm = (id) => {
    setCid(id);
    setConfirmBox(true);
  }

  const proceedToDelete = () => {
    setConfirmBox(false);
    return dispatch(deleteJobApplication(cid));
  }

  const sendToDetailPage = (id) => {
    return navigate(`/dashboard/viewJobApplication/${id}`);
  }

  useEffect(() => {
    if (deleteError) {
      setIsMessage({
        isThereMessage: true,
        messageType: "danger",
        message: deleteError
      });
      dispatch(clearErrors());
    }

    if (isDeleted) {
      setIsMessage({
        isThereMessage: true,
        messageType: "success",
        message: deleteMessage
      });
      dispatch(resetDelete());
    }

    dispatch(getJobApplication());
  }, [dispatch, isDeleted, deleteError, deleteMessage]);

  return (
    <>
      {loading || deleteLoading ? <Loader /> : <></>}
      <DashboardBreadcrumb title="Manage Job Vacancy" />
      {
        confirmBox
          ?
          <Conformation setConfirmBox={setConfirmBox} proceedFurther={proceedToDelete} message="Hy...Do you want to delete this job application?" btnName="DELETE" />
          :
          <></>
      }
      <section className="dashboard-operation-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="dashboard-data-table">
                {
                  isMessage.isThereMessage ? <Notify message={isMessage.message} type={isMessage.messageType} closeMessage={closeMessage} /> : <></>
                }
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>S.N</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Address</th>
                      <th>Applied For</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      jobsApplication && jobsApplication.map((data, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{data.name}</td>
                          <td>{data.email}</td>
                          <td>{data.phone}</td>
                          <td>{data.applyfor}</td>
                          <td className="table-action">
                            <button className="view" onClick={() => sendToDetailPage(data.id)}>VIEW</button>
                            <button className="delete" onClick={() => proceedToConfirm(data.id)}>DELETE</button>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default JobApplicationIndex