import React, { useEffect } from 'react';
import DashboardBreadcrumb from '../dashboardBreadcrumb/DashboardBreadcrumb';
import { resetDetail } from '../../../state/actions/contactAction';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import Loader from '../../../components/loader/Loader';
import { detailContact } from '../../../state/actions/contactAction';

const ViewContact = () => {
    const { id } = useParams();
    const dispatch = useDispatch();

    const { loading, contact } = useSelector(state => state.contactDetail);

    useEffect(() => {
        dispatch(detailContact(id));

        return () => {
            dispatch(resetDetail())
        }
    }, [dispatch, id]);
    return (
        <>
            {loading ? <Loader /> : <></>}
            <DashboardBreadcrumb title="Contact Detail" />
            <div className="dashboard-detail-page">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-10">
                            <div className="dashboard-detail-page-wrapper">
                                <div className="dashboard-detail-page-parent">
                                    <div className="dashboard-detail-page-childs">
                                        <p>Contact_ID -</p>
                                    </div>
                                    <div className="dashboard-detail-page-childs">
                                        <p>{contact && contact.id}</p>
                                    </div>
                                </div>
                                <div className="dashboard-detail-page-parent">
                                    <div className="dashboard-detail-page-childs">
                                        <p>Sender Name -</p>
                                    </div>
                                    <div className="dashboard-detail-page-childs">
                                        <p>{contact && contact.name}</p>
                                    </div>
                                </div>
                                <div className="dashboard-detail-page-parent">
                                    <div className="dashboard-detail-page-childs">
                                        <p>Sender Email -</p>
                                    </div>
                                    <div className="dashboard-detail-page-childs">
                                        <p>{contact && contact.email}</p>
                                    </div>
                                </div>
                                <div className="dashboard-detail-page-parent">
                                    <div className="dashboard-detail-page-childs">
                                        <p>Sender Phone -</p>
                                    </div>
                                    <div className="dashboard-detail-page-childs">
                                        <p>{contact && contact.phone}</p>
                                    </div>
                                </div>
                                <div className="dashboard-detail-page-parent">
                                    <div className="dashboard-detail-page-childs">
                                        <p>Interested For -</p>
                                    </div>
                                    <div className="dashboard-detail-page-childs">
                                        <p>{contact && contact.interestedfor}</p>
                                    </div>
                                </div>
                                <div className="dashboard-detail-page-parent">
                                    <div className="dashboard-detail-page-childs">
                                        <p>Message -</p>
                                    </div>
                                    <div className="dashboard-detail-page-childs">
                                        <p>{contact && contact.message}</p>
                                    </div>
                                </div>
                                <div className="dashboard-detail-page-parent">
                                    <div className="dashboard-detail-page-childs">
                                        <p>Message received - -</p>
                                    </div>
                                    <div className="dashboard-detail-page-childs">
                                        <p>{moment(contact && contact.created_at).format('MMMM Do YYYY, h:mm:ss a')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ViewContact;