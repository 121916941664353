import {
    CREATE_APPLICATION_REQUEST,
    CREATE_APPLICATION_SUCCESS,
    CREATE_APPLICATION_FAIL,
    GET_APPLICATION_REQUEST,
    GET_APPLICATION_SUCCESS,
    GET_APPLICATION_FAIL,
    DETAIL_APPLICATION_REQUEST,
    DETAIL_APPLICATION_SUCCESS,
    DETAIL_APPLICATION_FAIL,
    DELETE_APPLICATION_REQUEST,
    DELETE_APPLICATION_SUCCESS,
    DELETE_APPLICATION_FAIL,
    CLEAR_ERRORS,
    RESET_NEW,
    RESET_DELETE,
    RESET_DETAIL
} from '../constants/applicationConstant';
import axios from 'axios';

const globalLink = process.env.REACT_APP_API_URL;

// get application::begin
export const getApplication = () => async (dispatch) => {
    try {
        dispatch({ type: GET_APPLICATION_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const res = await axios.get(`${globalLink}/api/application/getApplication`, config);

        dispatch({ type: GET_APPLICATION_SUCCESS, payload: res });
    } catch (error) {
        dispatch({ type: GET_APPLICATION_FAIL, payload: error.response.data.message });
    }
}
// get application::end

// create application::begin
export const createApplication = (data) => async (dispatch) => {
    try {
        dispatch({ type: CREATE_APPLICATION_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",   
            }
        };

        const res = await axios.post(`${globalLink}/api/application/createApplication`, data, config);

        dispatch({ type: CREATE_APPLICATION_SUCCESS, payload: res });
    } catch (error) {
        dispatch({ type: CREATE_APPLICATION_FAIL, payload: error.response.data.message });
    }
}
// create application::end

// detail application::begin
export const detailApplication = (id) => async (dispatch) => {
    try {
        dispatch({ type: DETAIL_APPLICATION_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",  
            }
        };

        const res = await axios.get(`${globalLink}/api/application/detailApplication/${id}`, config);

        dispatch({ type: DETAIL_APPLICATION_SUCCESS, payload: res });
    } catch (error) {
        dispatch({ type: DETAIL_APPLICATION_FAIL, payload: error.response.data.message });
    }
}
// detail application::end

// delete application::begin
export const deleteApplication = (id) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_APPLICATION_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",  
            }
        };

        const res = await axios.delete(`${globalLink}/api/application/deleteApplication/${id}`, config);

        dispatch({ type: DELETE_APPLICATION_SUCCESS, payload: res });
    } catch (error) {
        dispatch({ type: DELETE_APPLICATION_FAIL, payload: error.response.data.message });
    }
}
// delete application::end

// resets::begin
export const resetNew = () => async (dispatch) => {
    dispatch({ type: RESET_NEW });
};

export const resetDelete = () => async (dispatch) => {
    dispatch({ type: RESET_DELETE });
};

export const resetDetail = () => async (dispatch) => {
    dispatch({ type: RESET_DETAIL });
};
// resets::end

// clearError::begin
export const clearErrors = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
};
// clearError::end