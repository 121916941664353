import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import DashboardBreadcrumb from '../dashboardBreadcrumb/DashboardBreadcrumb';
import moment from 'moment';
import Notify from '../notify/Notify';
import Conformation from '../conformation/Conformation';
import Loader from '../../../components/loader/Loader';
import { clearErrors, getContact, deleteContact, resetDelete } from '../../../state/actions/contactAction';

const ContactIndex = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { loading, contacts, error } = useSelector(state => state.contacts);
    const { loading: deleteLoading, error: deleteError, isDeleted, message: deleteMessage } = useSelector(state => state.contact);

    const [confirmBox, setConfirmBox] = useState(false);
    const [cid, setCid] = useState(null);

    const proceedToConfirm = (id) => {
        setCid(id);
        setConfirmBox(true);
    }

    const proceedToDelete = () => {
        setConfirmBox(false);
        return dispatch(deleteContact(cid));
    }

    const [isMessage, setIsMessage] = useState({
        isThereMessage: false,
        messageType: "",
        message: ""
    });

    const closeMessage = () => {
        return setIsMessage({
            isThereMessage: false,
            messageType: "",
            message: ""
        });
    }

    const sendToDetail = (id) => {
        return navigate(`/dashboard/viewContact/${id}`);
    }

    useEffect(() => {
        if (error) {
            setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: error
            });
            dispatch(clearErrors());
        }

        if (deleteError) {
            setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: deleteError
            });
            dispatch(clearErrors());
        }

        if (isDeleted) {
            setIsMessage({
                isThereMessage: true,
                messageType: "success",
                message: deleteMessage
            });
            dispatch(resetDelete());
        }

        dispatch(getContact());
    }, [dispatch, error, isDeleted, deleteError, deleteMessage]);
    return (
        <>
            {loading || deleteLoading ? <Loader /> : <></>}
            <DashboardBreadcrumb title="Manage Messages" />
            {
                confirmBox
                    ?
                    <Conformation setConfirmBox={setConfirmBox} proceedFurther={proceedToDelete} message="Hy...Do you want to delete this message?" btnName="DELETE" />
                    :
                    <></>
            }
            <section className="dashboard-operation-section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="dashboard-data-table">
                                {
                                    isMessage.isThereMessage ? <Notify message={isMessage.message} type={isMessage.messageType} closeMessage={closeMessage} /> : <></>
                                }
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>S.N</th>
                                            <th>Date</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Phone</th>
                                            <th>Interested For</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            contacts && contacts.map((data, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{moment(data.created_at).format('MMM Do YYYY')}</td>
                                                    <td>{data.name}</td>
                                                    <td>{data.email}</td>
                                                    <td>{data.phone}</td>
                                                    <td>{data.interestedfor}</td>
                                                    <td className="table-action">
                                                        <button onClick={() => sendToDetail(data.id)} className="view">VIEW</button>
                                                        <button onClick={() => proceedToConfirm(data.id)} className="delete">DELETE</button>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default ContactIndex;