import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTeam } from '../../state/actions/teamAction';
import Loader from '../../components/loader/Loader';
import ScrollTrigger from 'react-scroll-trigger';

const Team = () => {
    const dispatch = useDispatch();

    const { loading, teams } = useSelector(state => state.teams);

    const [counterOn, setCounterOn] = useState(false);

    useEffect(() => {
        dispatch(getTeam());
    }, [dispatch]);
    return (
        <>
            {loading ? <Loader /> : <></>}
            <section className="studymate-team-members-section">
                <div className="studymate-header studymate-header-center">
                    <h4>MEET OUR TEAM</h4>
                </div>
                <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                    <div className="container">
                        <div className="row">
                            {
                                teams && teams.map((data, index) => (
                                    <div className="col-md-3" key={index}>
                                        <div className={counterOn ? "team-member-card fadeUpAni" : "team-member-card"} style={{ "--i": index }}>
                                            <div className="team-member-image">
                                                <img src={data.image} alt="team_member" />
                                            </div>
                                            <div className="member-details">
                                                <h3>{data.name}</h3>
                                                <p>{data.designation}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </ScrollTrigger>
            </section>
        </>
    )
}

export default Team