import {
    GET_TEAM_REQUEST,
    GET_TEAM_SUCCESS,
    GET_TEAM_FAIL,
    CREATE_TEAM_REQUEST,
    CREATE_TEAM_SUCCESS,
    CREATE_TEAM_FAIL,
    UPDATE_TEAM_REQUEST,
    UPDATE_TEAM_SUCCESS,
    UPDATE_TEAM_FAIL,
    DELETE_TEAM_REQUEST,
    DELETE_TEAM_SUCCESS,
    DELETE_TEAM_FAIL,
    DETAIL_TEAM_REQUEST,
    DETAIL_TEAM_SUCCESS,
    DETAIL_TEAM_FAIL,
    RESET_NEW,
    RESET_UPDATE,
    RESET_DELETE,
    RESET_DETAIL,
    CLEAR_ERRORS
} from '../constants/teamConstant';

export const teamsReducer = (state = { teams: [] }, action) => {
    switch (action.type) {
        case GET_TEAM_REQUEST:
            return {
                loading: true,
                teams: []
            };

        case GET_TEAM_SUCCESS:
            return {
                ...state,
                loading: false,
                teams: action.payload.data.data
            };

        case GET_TEAM_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};

export const newTeamReducer = (state = { team: {} }, action) => {
    switch (action.type) {
        case CREATE_TEAM_REQUEST:
            return {
                loading: true,
            };

        case CREATE_TEAM_SUCCESS:
            return {
                ...state,
                loading: false,
                success: action.payload.data.success,
                message: action.payload.data.message
            };

        case CREATE_TEAM_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        case RESET_NEW:
            return {
                loading: false
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};

export const teamDetailReducer = (state = { team: {} }, action) => {
    switch (action.type) {
        case DETAIL_TEAM_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case DETAIL_TEAM_SUCCESS:
            return {
                loading: false,
                team: action.payload.data.data
            };

        case DETAIL_TEAM_FAIL:
            return {
                loading: false,
                error: action.payload,
            };

        case RESET_DETAIL:
            return {
                loading: false,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};

export const teamReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_TEAM_REQUEST:
        case UPDATE_TEAM_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case DELETE_TEAM_SUCCESS:
            return {
                ...state,
                loading: false,
                isDeleted: action.payload.data.success,
                message: action.payload.data.message
            };

        case UPDATE_TEAM_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: action.payload.data,
            };

        case DELETE_TEAM_FAIL:
        case UPDATE_TEAM_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case RESET_UPDATE:
            return {
                ...state,
                isUpdated: false,
            };

        case RESET_DELETE:
            return {
                loading: false,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};
