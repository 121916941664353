import {
    CREATE_GALLERY_REQUEST,
    CREATE_GALLERY_SUCCESS,
    CREATE_GALLERY_FAIL,
    GET_GALLERY_REQUEST,
    GET_GALLERY_SUCCESS,
    GET_GALLERY_FAIL,
    DELETE_GALLERY_REQUEST,
    DELETE_GALLERY_SUCCESS,
    DELETE_GALLERY_FAIL,
    CLEAR_ERRORS,
    RESET_NEW,
    RESET_DELETE,
} from '../constants/galleryConstant';
import axios from 'axios';

const globalLink = process.env.REACT_APP_API_URL;

// get gallery::begin
export const getGallery = () => async (dispatch) => {
    try {
        dispatch({ type: GET_GALLERY_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const res = await axios.get(`${globalLink}/api/gallery/getGallery`, config);

        dispatch({ type: GET_GALLERY_SUCCESS, payload: res });
    } catch (error) {
        dispatch({ type: GET_GALLERY_FAIL, payload: error.response.data.message });
    }
}
// get gallery::end

// create GALLERY::begin
export const createGallery = (data) => async (dispatch) => {
    try {
        dispatch({ type: CREATE_GALLERY_REQUEST });

        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        };

        const res = await axios.post(`${globalLink}/api/gallery/createGallery`, data, config);

        dispatch({ type: CREATE_GALLERY_SUCCESS, payload: res });
    } catch (error) {
        dispatch({ type: CREATE_GALLERY_FAIL, payload: error.response.data.message });
    }
}
// create gallery::end

// delete gallery::begin
export const deleteGallery = (id) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_GALLERY_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const res = await axios.delete(`${globalLink}/api/gallery/deleteGallery/${id}`, config);

        dispatch({ type: DELETE_GALLERY_SUCCESS, payload: res });
    } catch (error) {
        dispatch({ type: DELETE_GALLERY_FAIL, payload: error.response.data.message });
    }
}
// delete gallery::end

// resets::begin
export const resetNew = () => async (dispatch) => {
    dispatch({ type: RESET_NEW });
};

export const resetDelete = () => async (dispatch) => {
    dispatch({ type: RESET_DELETE });
};

// resets::end

// clearError::begin
export const clearErrors = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
};
// clearError::end