import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Conformation from './conformation/Conformation';
import { userLogout } from '../../state/actions/userAction';

const AdminSideBar = () => {
    const dispatch = useDispatch();
    const [confirmBox, setConfirmBox] = useState(false);

    const proceedToConfirm = () => {
        return setConfirmBox(true);
    }

    const proceedToLogout = () => {
        return dispatch(userLogout());
    }
    return (
        <>
            {
                confirmBox
                    ?
                    <Conformation setConfirmBox={setConfirmBox} proceedFurther={proceedToLogout} message="Hy, Do you want to logout?" btnName="LOGOUT" />
                    :
                    <></>
            }
            <div className="side-navigation">
                <ul>
                    <h3>MAIN</h3>
                    <li><Link to="/dashboard"><i className="fa fa-list-alt"></i>Dashboard</Link></li>
                    <li><Link to="/dashboard/changePassword"><i className="fa fa-desktop"></i>Security</Link></li>
                </ul>
                <ul>
                    <h3>LISTS</h3>
                    <li><Link to="/dashboard/manageService"><i className="fa fa-cogs"></i>Manage Services</Link></li>
                    <li><Link to="/dashboard/manageMdMessage"><i className="fa fa-comment-alt"></i>M.D. Message</Link></li>
                    <li><Link to="/dashboard/manageApplication"><i className="fa fa-users"></i>Applications</Link></li>
                    <li><Link to="/dashboard/manageTeam"><i className="fa fa-medal"></i>Team Members</Link></li>
                    <li><Link to="/dashboard/manageJob"><i className="fa fa-chalkboard-teacher"></i>Job Vacancy</Link></li>
                    <li><Link to="/dashboard/manageJobApplication"><i className="fa fa-user"></i>Job Application</Link></li>
                </ul>
                <ul>
                    <h3>NOTICES</h3>
                    <li><Link to="/dashboard/manageContact"><i className="fa fa-headphones-alt"></i>Messages</Link></li>
                    <li><Link to="/dashboard/managePop"><i className="fa fa-bell"></i>Popup Notification</Link></li>
                </ul>
                <ul>
                    <h3>GALLERY</h3>
                    <li><Link to="/dashboard/manageSlider"><i className="fa fa-images"></i>Slider</Link></li>
                    <li><Link to="/dashboard/manageGallery"><i className="fa fa-image"></i>Gallery</Link></li>
                    <li><Link to="/dashboard/manageUpload"><i className="fa fa-upload"></i>Uploads</Link></li>
                </ul>
                <ul>
                    <h3>ADMIN</h3>
                    <li><button onClick={proceedToConfirm} className="logoutBtn pointer text-danger"><i className="fa fa-sign-out-alt text-danger"></i> Logout</button></li>
                </ul>
            </div>
        </>
    )
}

export default AdminSideBar;