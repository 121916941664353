import {
    CREATE_POP_REQUEST,
    CREATE_POP_SUCCESS,
    CREATE_POP_FAIL,
    GET_POP_REQUEST,
    GET_POP_SUCCESS,
    GET_POP_FAIL,
    DELETE_POP_REQUEST,
    DELETE_POP_SUCCESS,
    DELETE_POP_FAIL,
    DETAIL_POP_REQUEST,
    DETAIL_POP_SUCCESS,
    DETAIL_POP_FAIL,
    UPDATE_POP_REQUEST,
    UPDATE_POP_SUCCESS,
    UPDATE_POP_FAIL,
    CLEAR_ERRORS,
    RESET_NEW,
    RESET_DELETE,
    RESET_DETAIL,
    RESET_UPDATE,
} from '../constants/popConstant';

export const popsReducer = (state = { pops: [] }, action) => {
    switch (action.type) {
        case GET_POP_REQUEST:
            return {
                loading: true,
                pops: []
            };

        case GET_POP_SUCCESS:
            return {
                ...state,
                loading: false,
                pops: action.payload.data.data
            };

        case GET_POP_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};

export const newPopReducer = (state = { pop: {} }, action) => {
    switch (action.type) {
        case CREATE_POP_REQUEST:
            return {
                loading: true,
            };

        case CREATE_POP_SUCCESS:
            return {
                ...state,
                loading: false,
                success: action.payload.data.success,
                message: action.payload.data.message
            };

        case CREATE_POP_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        case RESET_NEW:
            return {
                loading: false
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};

export const popReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_POP_REQUEST:
        case UPDATE_POP_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case DELETE_POP_SUCCESS:
            return {
                ...state,
                loading: false,
                isDeleted: action.payload.data.success,
                message: action.payload.data.message
            };

        case UPDATE_POP_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: action.payload.data,
            };

        case DELETE_POP_FAIL:
        case UPDATE_POP_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case RESET_DELETE:
            return {
                loading: false,
            };

        case RESET_UPDATE:
            return {
                ...state,
                isUpdated: false,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};

export const popDetailReducer = (state = { pop: {} }, action) => {
    switch (action.type) {
        case DETAIL_POP_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case DETAIL_POP_SUCCESS:
            return {
                loading: false,
                pop: action.payload.data.data
            };

        case DETAIL_POP_FAIL:
            return {
                loading: false,
                error: action.payload,
            };

        case RESET_DETAIL:
            return {
                loading: false,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};