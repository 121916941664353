import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DashboardBreadcrumb from '../dashboardBreadcrumb/DashboardBreadcrumb';
import Conformation from '../conformation/Conformation';
import CreateService from './CreateService';
import Notify from '../notify/Notify';
import Loader from '../../../components/loader/Loader';
import { useNavigate } from 'react-router-dom';
import { getService, deleteService, clearErrors, resetDelete } from '../../../state/actions/serviceAction';

const ServiceIndex = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { loading, services, error } = useSelector(state => state.services);
    const { loading: deleteLoading, error: deleteError, isDeleted, message: deleteMessage } = useSelector(state => state.service);

    const [isMessage, setIsMessage] = useState({
        isThereMessage: false,
        messageType: "",
        message: ""
    });

    const closeMessage = () => {
        return setIsMessage({
            isThereMessage: false,
            messageType: "",
            message: ""
        });
    }

    const [confirmBox, setConfirmBox] = useState(false);
    const [cid, setCid] = useState(null);

    const proceedToConfirm = (id) => {
        setCid(id);
        setConfirmBox(true);
    }

    const proceedToDelete = () => {
        setConfirmBox(false);
        return dispatch(deleteService(cid));
    }

    const navigateToDetailPage = (id) => {
        return navigate(`/dashboard/viewService/${id}`);
    }

    const navigateToUpdatePage = (id) => {
        return navigate(`/dashboard/updateService/${id}`);
    }

    useEffect(() => {
        if (deleteError) {
            setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: deleteError
            });
            dispatch(clearErrors());
        }

        if (isDeleted) {
            setIsMessage({
                isThereMessage: true,
                messageType: "success",
                message: deleteMessage
            });
            dispatch(resetDelete());
        }

        dispatch(getService("all"));
    }, [dispatch, error, isDeleted, deleteError, deleteMessage]);
    return (
        <>
            {loading || deleteLoading ? <Loader /> : <></>}
            <DashboardBreadcrumb title="Manage Services" />
            {
                confirmBox
                    ?
                    <Conformation setConfirmBox={setConfirmBox} proceedFurther={proceedToDelete} message="Hy...Do you want to delete this service?" btnName="DELETE" />
                    :
                    <></>
            }
            <section className="dashboard-operation-section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="dashboard-data-table">
                                {
                                    isMessage.isThereMessage ? <Notify message={isMessage.message} type={isMessage.messageType} closeMessage={closeMessage} /> : <></>
                                }
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Preview</th>
                                            <th>Title</th>
                                            <th>Category</th>
                                            <th>Orientation</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            services && services.map((data, index) => (
                                                <tr key={index}>
                                                    <td className="table-image">
                                                        <img src={data.image} draggable={false} alt="table" />
                                                    </td>
                                                    <td>{data.title}</td>
                                                    <td>{data.category}</td>
                                                    <td>{data.orientation}</td>
                                                    <td className="table-action">
                                                        <button className="view" onClick={() => navigateToDetailPage(data.id)}>VIEW</button>
                                                        <button className="update" onClick={() => navigateToUpdatePage(data.id)}>UPDATE</button>
                                                        <button className="delete" onClick={() => proceedToConfirm(data.id)}>DELETE</button>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <CreateService />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ServiceIndex;