import {
    CREATE_POP_REQUEST,
    CREATE_POP_SUCCESS,
    CREATE_POP_FAIL,
    GET_POP_REQUEST,
    GET_POP_SUCCESS,
    GET_POP_FAIL,
    DELETE_POP_REQUEST,
    DELETE_POP_SUCCESS,
    DELETE_POP_FAIL,
    DETAIL_POP_REQUEST,
    DETAIL_POP_SUCCESS,
    DETAIL_POP_FAIL,
    UPDATE_POP_REQUEST,
    UPDATE_POP_SUCCESS,
    UPDATE_POP_FAIL,
    CLEAR_ERRORS,
    RESET_NEW,
    RESET_DELETE,
    RESET_DETAIL,
    RESET_UPDATE,
} from '../constants/popConstant';
import axios from 'axios';

const globalLink = process.env.REACT_APP_API_URL;

// get pop::begin
export const getPop = (keyword) => async (dispatch) => {
    try {
        dispatch({ type: GET_POP_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const res = await axios.get(`${globalLink}/api/pop/getPop?keyword=${keyword}`, config);

        dispatch({ type: GET_POP_SUCCESS, payload: res });
    } catch (error) {
        dispatch({ type: GET_POP_FAIL, payload: error.response.data.message });
    }
}
// get pop::end

// create pop::begin
export const createPop = (data) => async (dispatch) => {
    try {
        dispatch({ type: CREATE_POP_REQUEST });

        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        };

        const res = await axios.post(`${globalLink}/api/pop/createPop`, data, config);

        dispatch({ type: CREATE_POP_SUCCESS, payload: res });
    } catch (error) {
        dispatch({ type: CREATE_POP_FAIL, payload: error.response.data.message });
    }
}
// create pop::end

// delete pop::begin
export const deletePop = (id) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_POP_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const res = await axios.delete(`${globalLink}/api/pop/deletePop/${id}`, config);

        dispatch({ type: DELETE_POP_SUCCESS, payload: res });
    } catch (error) {
        dispatch({ type: DELETE_POP_FAIL, payload: error.response.data.message });
    }
}
// delete pop::end

// detail pop::begin
export const detailPop = (id) => async (dispatch) => {
    try {
        dispatch({ type: DETAIL_POP_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const res = await axios.get(`${globalLink}/api/pop/detailPop/${id}`, config);

        dispatch({ type: DETAIL_POP_SUCCESS, payload: res });
    } catch (error) {
        dispatch({ type: DETAIL_POP_FAIL, payload: error.response.data.message });
    }
}
// detail pop::end

// update pop::begin
export const updatePop = (data, id) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_POP_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const res = await axios.put(`${globalLink}/api/pop/updatePop/${id}`, data, config);

        dispatch({ type: UPDATE_POP_SUCCESS, payload: res });
    } catch (error) {
        dispatch({ type: UPDATE_POP_FAIL, payload: error.response.data.message });
    }
}
// update pop::end

// resets::begin
export const resetNew = () => async (dispatch) => {
    dispatch({ type: RESET_NEW });
};

export const resetUpdate = () => async (dispatch) => {
    dispatch({ type: RESET_UPDATE });
};

export const resetDelete = () => async (dispatch) => {
    dispatch({ type: RESET_DELETE });
};

export const resetDetail = () => async (dispatch) => {
    dispatch({ type: RESET_DETAIL });
};

// resets::end

// clearError::begin
export const clearErrors = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
};
// clearError::end