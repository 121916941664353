import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Notify from '../notify/Notify';
import Loader from '../../../components/loader/Loader';
import { changePassword, clearErrors, resetPassword, userLogout } from '../../../state/actions/userAction';

const SecurityIndex = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const { loading, message, error, success } = useSelector(state => state.security);

    const [isMessage, setIsMessage] = useState({
        isThereMessage: false,
        messageType: "",
        message: ""
    });

    const closeMessage = () => {
        return setIsMessage({
            isThereMessage: false,
            messageType: "",
            message: ""
        });
    }

    const [passVal, setPssVal] = useState({
        oldPass: "",
        newPass: "",
        confirmPass: "",
    });

    const onChangeHandler = (e) => {
        setPssVal({ ...passVal, [e.target.name]: e.target.value });
    }

    const submitHandler = (e) => {
        e.preventDefault();

        if (!passVal.oldPass) {
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: "Please enter your current password!"
            });
        };

        if (!passVal.newPass) {
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: "Please enter new password!"
            });
        };

        if (!passVal.confirmPass) {
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: "Please re-enter password!"
            });
        };

        if (passVal.confirmPass !== passVal.newPass) {
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: "Password not match, try again!"
            });
        };

        const myForm = new FormData();
        myForm.append("oldPassword", passVal.oldPass);
        myForm.append("newPassword", passVal.newPass);

        dispatch(changePassword(myForm));
    }

    useEffect(() => {
        if (error) {
            setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: error
            });
            dispatch(clearErrors);
        }

        if (success) {
            setIsMessage({
                isThereMessage: true,
                messageType: "success",
                message: message
            });
            setPssVal({
                oldPass: "",
                newPass: "",
                confirmPass: "",
            });
            dispatch(resetPassword())
            dispatch(userLogout());
        };
    }, [dispatch, error, success, navigate, message]);

    return (
        <>
            {loading ? <Loader /> : <></>}
            <div className="container-fluid mt-2">
                <div className="row">
                    <div className="col-md-6">
                        <div className="dashboard-forms">
                            <h4>CHANGE YOUR PASSWORD</h4>
                            {
                                isMessage.isThereMessage ? <Notify message={isMessage.message} type={isMessage.messageType} closeMessage={closeMessage} /> : <></>
                            }
                            <form>
                                <div className="input-group">
                                    <label className="dashboard-label">Old Password</label>
                                    <input
                                        type="text"
                                        className="dashboard-input"
                                        placeholder="Enter your current password"
                                        name="oldPass"
                                        value={passVal.oldPass}
                                        onChange={(e) => onChangeHandler(e)}
                                    />
                                </div>
                                <div className="input-group">
                                    <label className="dashboard-label">New Password <span>(Please use !,~,@,#,$,%,^,&,* and numbers for more security.)</span></label>
                                    <input
                                        type="text"
                                        className="dashboard-input"
                                        placeholder="Enter your new password"
                                        name="newPass"
                                        value={passVal.newPass}
                                        onChange={(e) => onChangeHandler(e)}
                                    />
                                </div>
                                <div className="input-group">
                                    <label className="dashboard-label">Confirm Password</label>
                                    <input
                                        type="text"
                                        className="dashboard-input"
                                        placeholder="Please re-enter your password"
                                        name="confirmPass"
                                        value={passVal.confirmPass}
                                        onChange={(e) => onChangeHandler(e)}
                                    />
                                </div>
                                <div className="dashboard-submit-btn100">
                                    <button onClick={submitHandler}>CHANGE NOW</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SecurityIndex