import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Lightroom from 'react-lightbox-gallery'
import { getGallery } from '../../state/actions/galleryAction';
import Loader from '../../components/loader/Loader';

const ImageGallery = () => {
    const dispatch = useDispatch();

    const { gallerys, loading } = useSelector((state) => state.galleries) || [];

    var images = gallerys.map((dt) => (
        {
            src: dt.image
        }
    ));

    var settings = {
        columnCount: {
            default: 3,
            mobile: 3,
            tab: 4
        },
        mode: 'dark'
    }

    useEffect(() => {
        dispatch(getGallery());
    }, [dispatch]);
    return (
        <>
            {loading ? <Loader /> : <></>}
            {/* gallery::begin */}
            <section className="gallery-section">
                <div className="studymate-image-gallery">
                    <Lightroom images={images} settings={settings} />
                </div>
            </section>
            {/* gallery::end */}
        </>
    )
}

export default ImageGallery;