import {
    GET_TEAM_REQUEST,
    GET_TEAM_SUCCESS,
    GET_TEAM_FAIL,
    CREATE_TEAM_REQUEST,
    CREATE_TEAM_SUCCESS,
    CREATE_TEAM_FAIL,
    UPDATE_TEAM_REQUEST,
    UPDATE_TEAM_SUCCESS,
    UPDATE_TEAM_FAIL,
    DELETE_TEAM_REQUEST,
    DELETE_TEAM_SUCCESS,
    DELETE_TEAM_FAIL,
    DETAIL_TEAM_REQUEST,
    DETAIL_TEAM_SUCCESS,
    DETAIL_TEAM_FAIL,
    CLEAR_ERRORS,
    RESET_NEW,
    RESET_UPDATE,
    RESET_DELETE,
    RESET_DETAIL
} from '../constants/teamConstant';
import axios from 'axios';

const globalLink = process.env.REACT_APP_API_URL;

// get team::begin
export const getTeam = (keyword) => async (dispatch) => {
    try {
        dispatch({ type: GET_TEAM_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const res = await axios.get(`${globalLink}/api/TEAM/getTEAM?keyword=${keyword}`, config);

        dispatch({ type: GET_TEAM_SUCCESS, payload: res });
    } catch (error) {
        dispatch({ type: GET_TEAM_FAIL, payload: error.response.data.message });
    }
}
// get team::end

// create team::begin
export const createTeam = (data) => async (dispatch) => {
    try {
        dispatch({ type: CREATE_TEAM_REQUEST });

        const config = {
            headers: {
                "Content-Type": "multipart/form-data",   
            }
        };

        const res = await axios.post(`${globalLink}/api/team/createTeam`, data, config);

        dispatch({ type: CREATE_TEAM_SUCCESS, payload: res });
    } catch (error) {
        dispatch({ type: CREATE_TEAM_FAIL, payload: error.response.data.message });
    }
}
// create team::end

// delete team::begin
export const deleteTeam = (id) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_TEAM_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",  
            }
        };

        const res = await axios.delete(`${globalLink}/api/team/deleteTeam/${id}`, config);

        dispatch({ type: DELETE_TEAM_SUCCESS, payload: res });
    } catch (error) {
        dispatch({ type: DELETE_TEAM_FAIL, payload: error.response.data.message });
    }
}
// delete team::end

// detail team::begin
export const detailTeam = (id) => async (dispatch) => {
    try {
        dispatch({ type: DETAIL_TEAM_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",  
            }
        };

        const res = await axios.get(`${globalLink}/api/team/detailTeam/${id}`, config);

        dispatch({ type: DETAIL_TEAM_SUCCESS, payload: res });
    } catch (error) {
        dispatch({ type: DETAIL_TEAM_FAIL, payload: error.response.data.message });
    }
}
// detail team::end

// update team::begin
export const updateTeam = (id, data) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_TEAM_REQUEST });

        const config = {
            headers: {
                "Content-Type": "multipart/form-data", 
            }
        };

        const res = await axios.put(`${globalLink}/api/team/updateTeam/${id}`, data, config);

        dispatch({ type: UPDATE_TEAM_SUCCESS, payload: res });
    } catch (error) {
        dispatch({ type: UPDATE_TEAM_FAIL, payload: error.response.data.message });
    }
}
// update team::end

// resets::begin
export const resetNew = () => async (dispatch) => {
    dispatch({ type: RESET_NEW });
};

export const resetUpdate = () => async (dispatch) => {
    dispatch({ type: RESET_UPDATE });
};

export const resetDelete = () => async (dispatch) => {
    dispatch({ type: RESET_DELETE });
};

export const resetDetail = () => async (dispatch) => {
    dispatch({ type: RESET_DETAIL });
};
// resets::end

// clearError::begin
export const clearErrors = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
};
// clearError::end
