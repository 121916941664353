export const GET_APPLICATION_REQUEST = "GET_APPLICATION_REQUEST";
export const GET_APPLICATION_SUCCESS = "GET_APPLICATION_SUCCESS";
export const GET_APPLICATION_FAIL = "GET_APPLICATION_FAIL";

export const CREATE_APPLICATION_REQUEST = "CREATE_APPLICATION_REQUEST";
export const CREATE_APPLICATION_SUCCESS = "CREATE_APPLICATION_SUCCESS";
export const CREATE_APPLICATION_FAIL = "CREATE_APPLICATION_FAIL";

export const DETAIL_APPLICATION_REQUEST = "DETAIL_APPLICATION_REQUEST";
export const DETAIL_APPLICATION_SUCCESS = "DETAIL_APPLICATION_SUCCESS";
export const DETAIL_APPLICATION_FAIL = "DETAIL_APPLICATION_FAIL";

export const DELETE_APPLICATION_REQUEST = "DELETE_APPLICATION_REQUEST";
export const DELETE_APPLICATION_SUCCESS = "DELETE_APPLICATION_SUCCESS";
export const DELETE_APPLICATION_FAIL = "DELETE_APPLICATION_FAIL";

export const RESET_NEW = "RESET_NEW";
export const RESET_DELETE = "RESET_DELETE";
export const RESET_DETAIL = "RESET_DETAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";