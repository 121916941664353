import React, { useEffect } from 'react';
import DashboardBreadcrumb from '../dashboardBreadcrumb/DashboardBreadcrumb';
import { resetDetail, detailApplication } from '../../../state/actions/applicationAction';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import Loader from '../../../components/loader/Loader';

const ViewApplication = () => {
    const { id } = useParams();
    const dispatch = useDispatch();

    const { loading, application } = useSelector((state) => state.applicationDetail);

    useEffect(() => {
        dispatch(detailApplication(id));

        return () => {
            dispatch(resetDetail());
        }
    }, [dispatch, id]);

    return (
        <>
            {loading ? <Loader /> : <></>}
            <DashboardBreadcrumb title="Application Detail" />
            <div className="dashboard-detail-page">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-10">
                            <div className="dashboard-detail-page-wrapper">
                                <div className="dashboard-detail-page-parent">
                                    <div className="dashboard-detail-page-childs">
                                        <p>Application_ID -</p>
                                    </div>
                                    <div className="dashboard-detail-page-childs">
                                        <p>{application && application.id}</p>
                                    </div>
                                </div>
                                <div className="dashboard-detail-page-parent">
                                    <div className="dashboard-detail-page-childs">
                                        <p>Applicant Name -</p>
                                    </div>
                                    <div className="dashboard-detail-page-childs">
                                        <p>{application && application.name}</p>
                                    </div>
                                </div>
                                <div className="dashboard-detail-page-parent">
                                    <div className="dashboard-detail-page-childs">
                                        <p>Applicant Email -</p>
                                    </div>
                                    <div className="dashboard-detail-page-childs">
                                        <p>{application && application.email}</p>
                                    </div>
                                </div>
                                <div className="dashboard-detail-page-parent">
                                    <div className="dashboard-detail-page-childs">
                                        <p>Applicant Phone -</p>
                                    </div>
                                    <div className="dashboard-detail-page-childs">
                                        <p>{application && application.phone}</p>
                                    </div>
                                </div>
                                <div className="dashboard-detail-page-parent">
                                    <div className="dashboard-detail-page-childs">
                                        <p>Applicant Address -</p>
                                    </div>
                                    <div className="dashboard-detail-page-childs">
                                        <p>{application && application.address}</p>
                                    </div>
                                </div>
                                <div className="dashboard-detail-page-parent">
                                    <div className="dashboard-detail-page-childs">
                                        <p>Interested For -</p>
                                    </div>
                                    <div className="dashboard-detail-page-childs">
                                        <p>{application && application.interestedfor}</p>
                                    </div>
                                </div>
                                <div className="dashboard-detail-page-parent">
                                    <div className="dashboard-detail-page-childs">
                                        <p>Interested Country -</p>
                                    </div>
                                    <div className="dashboard-detail-page-childs">
                                        <p>{application && application.country}</p>
                                    </div>
                                </div>
                                <div className="dashboard-detail-page-parent">
                                    <div className="dashboard-detail-page-childs">
                                        <p>Message -</p>
                                    </div>
                                    <div className="dashboard-detail-page-childs">
                                        <p>{application && application.message}</p>
                                    </div>
                                </div>
                                <div className="dashboard-detail-page-parent">
                                    <div className="dashboard-detail-page-childs">
                                        <p>Application received -</p>
                                    </div>
                                    <div className="dashboard-detail-page-childs">
                                        <p>{moment(application && application.created_at).format('MMMM Do YYYY, h:mm:ss a')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ViewApplication