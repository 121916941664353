import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import team from '../../../assets/images/design/studymate-team.jpg';
import Breadcrumb from '../../../components/breadcrumb/Breadcrumb';
import Loader from '../../../components/loader/Loader';
import Notify from '../../../pages/dashboard/notify/Notify';
import { createJobApplication, detailJob, resetNew } from '../../../state/actions/jobAction';

const CareerApplication = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();

    const { loading, success, error } = useSelector(state => state.newJobApplication);
    const { loadingDetail, job } = useSelector(state => state.jobDetail);

    const [isMessage, setIsMessage] = useState({
        isThereMessage: false,
        messageType: "",
        message: ""
    });

    const closeMessage = () => {
        return setIsMessage({
            isThereMessage: false,
            messageType: "",
            message: ""
        });
    }

    const [applicationVal, setApplicationVal] = useState({
        name: "",
        email: "",
        phone: "",
        address: "",
        experience: "",
        applyFor: "",
        coverLetter: "",
    });

    const [file, setFile] = useState(null);
    const [isAgree, setIsAgree] = useState(false);

    const onChangeHandler = (e) => {
        return setApplicationVal({ ...applicationVal, [e.target.name]: e.target.value })
    }

    const submitHandler = (e) => {
        e.preventDefault();

        if (!applicationVal.name || !applicationVal.email || !applicationVal.phone || !applicationVal.address || !applicationVal.experience || !applicationVal.applyFor || !applicationVal.coverLetter) {
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: "Mandatory field is missing!"
            });
        };

        if (applicationVal.phone.length !== 10) {
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: "Phone number shound include 10 digits!"
            });
        };

        if (applicationVal.email.includes("@gmail.com") !== true) {
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: "Email is invalid!"
            });
        };

        if (file === null) {
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: "Please provide your Resume/CV!"
            });
        };

        if ((file.size / 1024) >= 800) {
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: "File size should be less than '800kb'!"
            });
        };

        if (isAgree === false) {
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: "Please check our teams and conditions!"
            });
        }

        const myForm = new FormData();
        myForm.append("name", applicationVal.name);
        myForm.append("email", applicationVal.email);
        myForm.append("phone", applicationVal.phone);
        myForm.append("address", applicationVal.address);
        myForm.append("experience", applicationVal.experience);
        myForm.append("applyfor", applicationVal.applyFor);
        myForm.append("coverletter", applicationVal.coverLetter);
        myForm.append("resume", file);

        return dispatch(createJobApplication(myForm));
    }

    useEffect(() => {
        if (error) {
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: error
            });
        }
        if (success) {
            dispatch(resetNew());
            navigate("/success");
        }
    }, [dispatch, success, navigate, error]);

    useEffect(() => {
        dispatch(detailJob(id));
        setApplicationVal({
            applyFor: job.designation
        });

    }, [dispatch, id, job.designation]);

    return (
        <>
            {loading || loadingDetail ? <Loader /> : <></>}
            <Breadcrumb title="Apply Now" />
            <div className="career-application">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="career-application-form">
                                <div className="application-form-container">
                                    <div className="application-inner">
                                        <h5 className="mb-4">APPLY NOW -</h5>
                                        {
                                            isMessage.isThereMessage ? <Notify message={isMessage.message} type={isMessage.messageType} closeMessage={closeMessage} /> : <></>
                                        }
                                        <form className="global-form">
                                            <div className="container-fluid">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="input-group">
                                                            <label className="label">Enter Your Name</label><span>*</span>
                                                            <input
                                                                className="input"
                                                                type="text"
                                                                placeholder="Enter Full Name"
                                                                name="name"
                                                                value={applicationVal.name}
                                                                onChange={(e) => onChangeHandler(e)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="input-group">
                                                            <label>Email</label><span>*</span>
                                                            <input
                                                                type="email"
                                                                placeholder="Ex - studymate.edu.np@gmail.com"
                                                                name="email"
                                                                value={applicationVal.email}
                                                                onChange={(e) => onChangeHandler(e)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="input-group">
                                                            <label>Mobile No.</label><span>*</span>
                                                            <input
                                                                type="number"
                                                                placeholder="+977 - Mobile No"
                                                                name="phone"
                                                                value={applicationVal.phone}
                                                                onChange={(e) => onChangeHandler(e)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="input-group">
                                                            <label>Address</label><span>*</span>
                                                            <input
                                                                type="text"
                                                                placeholder="Enter Address"
                                                                name="address"
                                                                value={applicationVal.address}
                                                                onChange={(e) => onChangeHandler(e)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="input-group">
                                                            <label>Experience</label><span>*</span>
                                                            <input
                                                                type="text"
                                                                placeholder="Enter Experience"
                                                                name="experience"
                                                                value={applicationVal.experience}
                                                                onChange={(e) => onChangeHandler(e)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="input-group">
                                                            <label>Apply For</label><span>*</span>
                                                            <input
                                                                type="text"
                                                                placeholder="Apply for"
                                                                name="applyFor"
                                                                value={applicationVal.applyFor}
                                                                onChange={(e) => onChangeHandler(e)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="input-group">
                                                            <label>Cover Letter</label>
                                                            <textarea
                                                                cols="10"
                                                                rows="5"
                                                                name="coverLetter"
                                                                value={applicationVal.coverLetter}
                                                                onChange={(e) => onChangeHandler(e)}
                                                            >
                                                            </textarea>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="input-group">
                                                            <label>Resume / cv</label><span>* (Please choose jpg, jpeg, png, .pdf,.docx)</span>
                                                            <input
                                                                type="file"
                                                                placeholder="Enter Address"
                                                                accept="image/*,.pdf, .docx"
                                                                onChange={(e) => setFile(e.target.files[0])}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="submit-btn">
                                                            <button onClick={submitHandler}>Apply <i className="fa fa-paper-plane"></i></button>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="iaccept">
                                                            <input
                                                                type="checkbox"
                                                                required
                                                                onChange={(e) => setIsAgree(e.target.checked)}
                                                            /> <span>I accept all terms and conditions of StudyMate International Educational Consultancy Pvt. Ltd.</span>
                                                            <p>* - Field is required</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="application-short-details">
                                <h3>We are hiring expert frontend developer( ReactJS )</h3>
                                <p>Applying for: Frontend Developer</p>
                            </div>
                            <div className="application-deco-image">
                                <img src={team} draggable={false} alt="studymate-team" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CareerApplication;