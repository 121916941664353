import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom';
import Breadcrumb from '../../../components/breadcrumb/Breadcrumb';
import Loader from '../../../components/loader/Loader';
import { detailJob } from '../../../state/actions/jobAction';

const CareerDetail = () => {
    const dispatch = useDispatch();
    const { id } = useParams();

    const { loading, job } = useSelector(state => state.jobDetail);

    useEffect(() => {
        dispatch(detailJob(id));
    }, [dispatch, id]);
    return (
        <>
            {loading ? <Loader /> : <></>}
            <Breadcrumb title="Job Detail" />
            <div className="studymate-career-detail-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 m-auto">
                            <div className="job-short-detail">
                                <span>{job && job.designation}</span>
                                <h3>{job && job.title}</h3>
                                <p>{job && job.jobtype} | {job && job.location} | {job && job.jobtype}</p>
                                <div className="apply-btns">
                                    <Link to="/career"><i className="fa fa-angle-left"></i> Back</Link>
                                    <Link to={`/careerApplication/${job.id}`}>Apply Now</Link>
                                </div>
                            </div>
                            <div className="job-description">
                                <div dangerouslySetInnerHTML={{ __html: job && job.description }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CareerDetail