import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import DashboardBreadcrumb from '../dashboardBreadcrumb/DashboardBreadcrumb';
import Loader from '../../../components/loader/Loader';
import { detailJob, resetDetail } from '../../../state/actions/jobAction';

const ViewJob = () => {
    const { id } = useParams();
    const dispatch = useDispatch();

    const { job, loading } = useSelector(state => state.jobDetail);

    useEffect(() => {
        dispatch(detailJob(id));

        return () => {
            dispatch(resetDetail());
        }
    }, [dispatch, id]);
    return (
        <>
            {loading ? <Loader /> : <></>}
            <DashboardBreadcrumb title="Vacancy Detail" />
            <div className="dashboard-detail-page">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-10">
                            <div className="dashboard-detail-page-wrapper">
                                <div className="dashboard-detail-page-parent">
                                    <div className="dashboard-detail-page-childs">
                                        <p>Title -</p>
                                    </div>
                                    <div className="dashboard-detail-page-childs">
                                        <p>{job && job.title}</p>
                                    </div>
                                </div>
                                <div className="dashboard-detail-page-parent">
                                    <div className="dashboard-detail-page-childs">
                                        <p>Designation -</p>
                                    </div>
                                    <div className="dashboard-detail-page-childs">
                                        <p>{job && job.designation}</p>
                                    </div>
                                </div>
                                <div className="dashboard-detail-page-parent">
                                    <div className="dashboard-detail-page-childs">
                                        <p>Orientation -</p>
                                    </div>
                                    <div className="dashboard-detail-page-childs">
                                        <p>{job && job.orientation}</p>
                                    </div>
                                </div>
                                <div className="dashboard-detail-page-parent">
                                    <div className="dashboard-detail-page-childs">
                                        <p>Job Type -</p>
                                    </div>
                                    <div className="dashboard-detail-page-childs">
                                        <p>{job && job.jobtype}</p>
                                    </div>
                                </div>
                                <div className="dashboard-detail-page-parent">
                                    <div className="dashboard-detail-page-childs">
                                        <p>Location -</p>
                                    </div>
                                    <div className="dashboard-detail-page-childs">
                                        <p>{job && job.location}</p>
                                    </div>
                                </div>
                                <div className="dashboard-detail-page-parent">
                                    <div className="dashboard-detail-page-childs">
                                        <p>Visibility -</p>
                                    </div>
                                    <div className="dashboard-detail-page-childs">
                                        <p>{job && job.visibility}</p>
                                    </div>
                                </div>
                                <div className="dashboard-detail-page-parent">
                                    <div className="dashboard-detail-page-childs">
                                        <p>Thumbnail -</p>
                                    </div>
                                    <div className="dashboard-detail-page-childs">
                                        <ul>
                                            <li><img src={job && job.image} alt="thumbnail" draggable={false} /></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="dashboard-detail-page-parent">
                                    <div className="dashboard-detail-page-childs">
                                        <p>Description -</p>
                                    </div>
                                    <div className="dashboard-detail-page-childs">
                                        <div dangerouslySetInnerHTML={{ __html: job && job.description }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ViewJob;