import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Notify from '../notify/Notify';
import Loader from '../../../components/loader/Loader';
import { clearErrors, detailPop, resetDetail, resetUpdate, updatePop } from '../../../state/actions/popAction';
import { useNavigate, useParams } from 'react-router-dom';

const UpdatePop = () => {

    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { pop, loading, error } = useSelector((state) => state.popDetail);
    const { loading: updateLoading, isUpdated, error: updateError } = useSelector(state => state.pop);

    const [isMessage, setIsMessage] = useState({
        isThereMessage: false,
        messageType: "",
        message: ""
    });

    const closeMessage = () => {
        return setIsMessage({
            isThereMessage: false,
            messageType: "",
            message: ""
        });
    }

    const [popupVal, setPopupVal] = useState({
        orientation: "",
        appearance: "",
    });

    const onChangeHandler = (e) => {
        setPopupVal({ ...popupVal, [e.target.name]: e.target.value });
    }

    const submitHandller = (e) => {
        e.preventDefault();

        if (!popupVal.orientation || !popupVal.appearance) {
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: "Required field is missing!"
            });
        }

        const myForm = new FormData();
        myForm.append("orientation", popupVal.orientation);
        myForm.append("visibility", popupVal.appearance);

        dispatch(updatePop(myForm, id));
    }

    useEffect(() => {
        if (pop.id) {
            setPopupVal({
                orientation: pop.orientation,
                appearance: pop.visibility,
            });
        }

        if (error) {
            setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: error
            });
            dispatch(clearErrors());
        }

        if (isUpdated) {
            setIsMessage({
                isThereMessage: true,
                messageType: "success",
                message: isUpdated.message
            });
            dispatch(resetDetail());
            dispatch(resetUpdate());
            navigate("/dashboard/managePop");
        }

        if (updateError) {
            setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: updateError
            });
            dispatch(clearErrors());
        }

        dispatch(detailPop(id));

    }, [
        dispatch, 
        id, 
        error, 
        pop.id, 
        pop.orientation, 
        pop.visibility,
        navigate,
        isUpdated, 
        updateError
    ]);

    return (
        <>
            {loading || updateLoading ? <Loader /> : <></>}
            <div className="dashboard-forms">
                <h4>UPDATE POPUP NOTIFICATION</h4>
                {
                    isMessage.isThereMessage ? <Notify message={isMessage.message} type={isMessage.messageType} closeMessage={closeMessage} /> : <></>
                }
                <form>
                    <div className="input-group">
                        <label className="dashboard-label">Orienatation <span>(This will insure which notification comes first.)</span></label>
                        <input
                            type="number"
                            className="dashboard-input"
                            placeholder="Enter orientation"
                            name="orientation"
                            value={popupVal.orientation}
                            onChange={(e) => onChangeHandler(e)}
                        />
                    </div>
                    <div className="input-group">
                        <label className="dashboard-label">Appearance <span>(This will be responsible for showing and hiding the notification..)</span></label>
                        <select className="dashboard-input"
                            name="appearance"
                            value={popupVal.appearance}
                            onChange={(e) => onChangeHandler(e)}
                        >
                            <option>- Visible/Hidden -</option>
                            <option value="visible">Visible</option>
                            <option value="hidden">Hidden</option>
                        </select>
                    </div>
                    <div className="dashboard-submit-btn100">
                        <button onClick={submitHandller}>UPDATE</button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default UpdatePop;