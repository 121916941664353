import {
    CREATE_APPLICATION_REQUEST,
    CREATE_APPLICATION_SUCCESS,
    CREATE_APPLICATION_FAIL,
    GET_APPLICATION_REQUEST,
    GET_APPLICATION_SUCCESS,
    GET_APPLICATION_FAIL,
    DETAIL_APPLICATION_REQUEST,
    DETAIL_APPLICATION_SUCCESS,
    DETAIL_APPLICATION_FAIL,
    DELETE_APPLICATION_REQUEST,
    DELETE_APPLICATION_SUCCESS,
    DELETE_APPLICATION_FAIL,
    CLEAR_ERRORS,
    RESET_NEW,
    RESET_DELETE,
    RESET_DETAIL
} from '../constants/applicationConstant';

export const applicationsReducer = (state = { applications: [] }, action) => {
    switch (action.type) {
        case GET_APPLICATION_REQUEST:
            return {
                loading: true,
                applications: []
            };

        case GET_APPLICATION_SUCCESS:
            return {
                ...state,
                loading: false,
                applications: action.payload.data.data
            };

        case GET_APPLICATION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};

export const newApplicationReducer = (state = { application: {} }, action) => {
    switch (action.type) {
        case CREATE_APPLICATION_REQUEST:
            return {
                loading: true,
            };

        case CREATE_APPLICATION_SUCCESS:
            return {
                ...state,
                loading: false,
                success: action.payload.data.success,
                message: action.payload.data.message
            };

        case CREATE_APPLICATION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        case RESET_NEW:
            return {
                loading: false
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};


export const applicationDetailReducer = (state = { application: {} }, action) => {
    switch (action.type) {
        case DETAIL_APPLICATION_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case DETAIL_APPLICATION_SUCCESS:
            return {
                loading: false,
                application: action.payload.data.data
            };

        case DETAIL_APPLICATION_FAIL:
            return {
                loading: false,
                error: action.payload,
            };

        case RESET_DETAIL:
            return {
                loading: false,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};

export const applicationReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_APPLICATION_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case DELETE_APPLICATION_SUCCESS:
            return {
                ...state,
                loading: false,
                isDeleted: action.payload.data.success,
                message: action.payload.data.message
            };

        case DELETE_APPLICATION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case RESET_DELETE:
            return {
                loading: false,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};