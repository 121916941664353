export const GET_TEAM_REQUEST = "GET_TEAM_REQUEST";
export const GET_TEAM_SUCCESS = "GET_TEAM_SUCCESS";
export const GET_TEAM_FAIL = "GET_TEAM_FAIL";

export const CREATE_TEAM_REQUEST = "CREATE_TEAM_REQUEST";
export const CREATE_TEAM_SUCCESS = "CREATE_TEAM_SUCCESS";
export const CREATE_TEAM_FAIL = "CREATE_TEAM_FAIL";

export const DELETE_TEAM_REQUEST = "DELETE_TEAM_REQUEST";
export const DELETE_TEAM_SUCCESS = "DELETE_TEAM_SUCCESS";
export const DELETE_TEAM_FAIL = "DELETE_TEAM_FAIL";

export const DETAIL_TEAM_REQUEST = "DETAIL_TEAM_REQUEST";
export const DETAIL_TEAM_SUCCESS = "DETAIL_TEAM_SUCCESS";
export const DETAIL_TEAM_FAIL = "DETAIL_TEAM_FAIL";

export const UPDATE_TEAM_REQUEST = "UPDATE_TEAM_REQUEST";
export const UPDATE_TEAM_SUCCESS = "UPDATE_TEAM_SUCCESS";
export const UPDATE_TEAM_FAIL = "UPDATE_TEAM_FAIL";

export const RESET_NEW = "RESET_NEW";
export const RESET_DELETE = "RESET_DELETE";
export const RESET_DETAIL = "RESET_DETAIL";
export const RESET_UPDATE = "RESET_UPDATE";

export const CLEAR_ERRORS = "CLEAR_ERRORS";