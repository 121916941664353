export const GET_SERVICE_REQUEST = "GET_SERVICE_REQUEST";
export const GET_SERVICE_SUCCESS = "GET_SERVICE_SUCCESS";
export const GET_SERVICE_FAIL = "GET_SERVICE_FAIL";

export const CREATE_SERVICE_REQUEST = "CREATE_SERVICE_REQUEST";
export const CREATE_SERVICE_SUCCESS = "CREATE_SERVICE_SUCCESS";
export const CREATE_SERVICE_FAIL = "CREATE_SERVICE_FAIL";

export const DELETE_SERVICE_REQUEST = "DELETE_SERVICE_REQUEST";
export const DELETE_SERVICE_SUCCESS = "DELETE_SERVICE_SUCCESS";
export const DELETE_SERVICE_FAIL = "DELETE_SERVICE_FAIL";

export const DETAIL_SERVICE_REQUEST = "DETAIL_SERVICE_REQUEST";
export const DETAIL_SERVICE_SUCCESS = "DETAIL_SERVICE_SUCCESS";
export const DETAIL_SERVICE_FAIL = "DETAIL_SERVICE_FAIL";

export const UPDATE_SERVICE_REQUEST = "UPDATE_SERVICE_REQUEST";
export const UPDATE_SERVICE_SUCCESS = "UPDATE_SERVICE_SUCCESS";
export const UPDATE_SERVICE_FAIL = "UPDATE_SERVICE_FAIL";

export const RESET_NEW = "RESET_NEW";
export const RESET_DELETE = "RESET_DELETE";
export const RESET_DETAIL = "RESET_DETAIL";
export const RESET_UPDATE = "RESET_UPDATE";

export const CLEAR_ERRORS = "CLEAR_ERRORS";