import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Notify from '../notify/Notify';
import Loader from '../../../components/loader/Loader';
import Conformation from '../conformation/Conformation';
import DashboardBreadcrumb from '../dashboardBreadcrumb/DashboardBreadcrumb';
import CreateGallery from './CreateGallery';
import { clearErrors, deleteGallery, getGallery, resetDelete } from '../../../state/actions/galleryAction';

const GalleryIndex = () => {
    const dispatch = useDispatch();

    const { gallerys, loading } = useSelector(state => state.galleries);
    const { loading: deleteLoading, error: deleteError, isDeleted, message: deleteMessage } = useSelector(state => state.gallery);

    const [isMessage, setIsMessage] = useState({
        isThereMessage: false,
        messageType: "",
        message: ""
    });

    const closeMessage = () => {
        return setIsMessage({
            isThereMessage: false,
            messageType: "",
            message: ""
        });
    }

    const [confirmBox, setConfirmBox] = useState(false);
    const [cid, setCid] = useState(null);

    const proceedToConfirm = (id) => {
        setCid(id);
        setConfirmBox(true);
    }

    const proceedToDelete = () => {
        setConfirmBox(false);
        return dispatch(deleteGallery(cid));
    }

    useEffect(() => {
        if (deleteError) {
            setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: deleteError
            });
            dispatch(clearErrors());
        }

        if (isDeleted) {
            setIsMessage({
                isThereMessage: true,
                messageType: "success",
                message: deleteMessage
            });
            dispatch(resetDelete());
        }
        dispatch(getGallery());
    }, [dispatch, isDeleted, deleteError, deleteMessage]);
    return (
        <>
            {loading || deleteLoading ? <Loader /> : <></>}
            <DashboardBreadcrumb title="Manage Gallery Images" />
            {
                confirmBox
                    ?
                    <Conformation setConfirmBox={setConfirmBox} proceedFurther={proceedToDelete} message="Hy...Do you want to delete this slider image?" btnName="DELETE" />
                    :
                    <></>
            }
            <section className="dashboard-operation-section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6">
                            {
                                isMessage.isThereMessage ? <Notify message={isMessage.message} type={isMessage.messageType} closeMessage={closeMessage} /> : <></>
                            }
                            <div className="row">
                                {
                                    gallerys && gallerys.map((data, index) => (
                                        <div className="col-md-6" key={index}>
                                            <div className="media-card-wrapper">
                                                <div className="media-card-image">
                                                    <img src={data.image} alt="prev" />
                                                </div>
                                                <p className="create_date">Date - {moment(data.created_at).format('MMM Do YYYY, h:mm:ss a')}</p>
                                                <div className="media-action-btns">
                                                    <button className="delete-btn" onClick={() => proceedToConfirm(data.id)}>DELETE</button>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className="col-md-6">
                            <CreateGallery />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default GalleryIndex