export const GET_SLIDER_REQUEST = "GET_SLIDER_REQUEST";
export const GET_SLIDER_SUCCESS = "GET_SLIDER_SUCCESS";
export const GET_SLIDER_FAIL = "GET_SLIDER_FAIL";

export const CREATE_SLIDER_REQUEST = "CREATE_SLIDER_REQUEST";
export const CREATE_SLIDER_SUCCESS = "CREATE_SLIDER_SUCCESS";
export const CREATE_SLIDER_FAIL = "CREATE_SLIDER_FAIL";

export const DELETE_SLIDER_REQUEST = "DELETE_SLIDER_REQUEST";
export const DELETE_SLIDER_SUCCESS = "DELETE_SLIDER_SUCCESS";
export const DELETE_SLIDER_FAIL = "DELETE_SLIDER_FAIL";

export const RESET_NEW = "RESET_NEW";
export const RESET_DELETE = "RESET_DELETE";
export const RESET_DETAIL = "RESET_DETAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";