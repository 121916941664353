import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../src/components/loader/Loader';
import WebTrafic from './charts/WebTrafic';
import { getWebInfo } from '../../state/actions/webInfoAction';

const DashboardLanding = () => {
    const dispatch = useDispatch();

    const { loading, upload, trafic, slider, message, pop, team, application, service } = useSelector(state => state.webInfo.webInfo);

    useEffect(() => {
        dispatch(getWebInfo());
    }, [dispatch]);
    return (
        <>
            {loading ? <Loader /> : <></>}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-3">
                        <div className="report-card-parent">
                            <div className="report-card-childs">
                                <p>WEB TRAFIC</p>
                                <h3>{trafic && trafic.count}</h3>
                                <Link to="/dashboard">Analyzing...</Link>
                            </div>
                            <div className="report-card-childs">
                                <i className="fa fa-globe"></i>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="report-card-parent">
                            <div className="report-card-childs">
                                <p>MESSAGES</p>
                                <h3>{message && message.countMessage}</h3>
                                <Link to="/dashboard/manageContact">View All <i className="fa fa-arrow-right"></i></Link>
                            </div>
                            <div className="report-card-childs">
                                <i className="fa fa-user-check"></i>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="report-card-parent">
                            <div className="report-card-childs">
                                <p>SLIDER IMAGES</p>
                                <h3>{slider && slider.countSlider}</h3>
                                <Link to="/dashboard/manageSlider">View All <i className="fa fa-arrow-right"></i></Link>
                            </div>
                            <div className="report-card-childs">
                                <i className="fa fa-photo-video"></i>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="report-card-parent">
                            <div className="report-card-childs">
                                <p>POPUP NOTIFICATION</p>
                                <h3>{pop && pop.countPop}</h3>
                                <Link to="/dashboard/managePop">View All <i className="fa fa-arrow-right"></i></Link>
                            </div>
                            <div className="report-card-childs">
                                <i className="fa fa-bell"></i>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="report-card-parent">
                            <div className="report-card-childs">
                                <p>APPLICATION</p>
                                <h3>{application && application.countApplication}</h3>
                                <Link to="/dashboard/manageApplication">View All <i className="fa fa-arrow-right"></i></Link>
                            </div>
                            <div className="report-card-childs">
                                <i className="fa fa-file-alt"></i>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="report-card-parent">
                            <div className="report-card-childs">
                                <p>TEAM MEMBERS</p>
                                <h3>{team && team.countTeam}</h3>
                                <Link to="/dashboard/manageTeam">View All <i className="fa fa-arrow-right"></i></Link>
                            </div>
                            <div className="report-card-childs">
                                <i className="fa fa-users"></i>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="report-card-parent">
                            <div className="report-card-childs">
                                <p>SERVICES</p>
                                <h3>{service && service.countService}</h3>
                                <Link to="/dashboard/manageService">View All <i className="fa fa-arrow-right"></i></Link>
                            </div>
                            <div className="report-card-childs">
                                <i className="fa fa-bars"></i>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="report-card-parent">
                            <div className="report-card-childs">
                                <p>UPLOADS</p>
                                <h3>{upload && upload.countUpload}</h3>
                                <Link to="/dashboard/manageUpload">View All <i className="fa fa-arrow-right"></i></Link>
                            </div>
                            <div className="report-card-childs">
                                <i className="fa fa-chalkboard-teacher"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <WebTrafic count={trafic && trafic.count} />
        </>
    )
}

export default DashboardLanding;