const BrandMessage = () => {
    return (
      <div className="brand-message">
          <div className="brand-message-inner">
              <h3>StudyMate International Educational Consultancy Pvt. Ltd.</h3>
              <p>"Your True Education Partner"</p>
          </div>
      </div>
    )
  }
  
  export default BrandMessage;