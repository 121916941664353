import {
    GET_JOB_REQUEST,
    GET_JOB_SUCCESS,
    GET_JOB_FAIL,
    CREATE_JOB_REQUEST,
    CREATE_JOB_SUCCESS,
    CREATE_JOB_FAIL,
    UPDATE_JOB_REQUEST,
    UPDATE_JOB_SUCCESS,
    UPDATE_JOB_FAIL,
    DELETE_JOB_REQUEST,
    DELETE_JOB_SUCCESS,
    DELETE_JOB_FAIL,
    DETAIL_JOB_REQUEST,
    DETAIL_JOB_SUCCESS,
    DETAIL_JOB_FAIL,
    CLEAR_ERRORS,
    RESET_NEW,
    RESET_UPDATE,
    RESET_DELETE,
    RESET_DETAIL,
    GET_JOB_APPLICATION_REQUEST,
    GET_JOB_APPLICATION_SUCCESS,
    GET_JOB_APPLICATION_FAIL,
    CREATE_JOB_APPLICATION_REQUEST,
    CREATE_JOB_APPLICATION_SUCCESS,
    CREATE_JOB_APPLICATION_FAIL,
    DELETE_JOB_APPLICATION_REQUEST,
    DELETE_JOB_APPLICATION_SUCCESS,
    DELETE_JOB_APPLICATION_FAIL,
    DETAIL_JOB_APPLICATION_REQUEST,
    DETAIL_JOB_APPLICATION_SUCCESS,
    DETAIL_JOB_APPLICATION_FAIL,
} from '../constants/jobConstant';
import axios from 'axios';

const globalLink = process.env.REACT_APP_API_URL;

// get JOB::begin
export const getJob = () => async (dispatch) => {
    try {
        dispatch({ type: GET_JOB_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const res = await axios.get(`${globalLink}/api/job/getJob`, config);

        dispatch({ type: GET_JOB_SUCCESS, payload: res });
    } catch (error) {
        dispatch({ type: GET_JOB_FAIL, payload: error.response.data.message });
    }
}
// get JOB::end

// create JOB::begin
export const createJob = (data) => async (dispatch) => {
    try {
        dispatch({ type: CREATE_JOB_REQUEST });

        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        };

        const res = await axios.post(`${globalLink}/api/job/createJob`, data, config);

        dispatch({ type: CREATE_JOB_SUCCESS, payload: res });
    } catch (error) {
        dispatch({ type: CREATE_JOB_FAIL, payload: error.response.data.message });
    }
}
// create JOB::end

// delete JOB::begin
export const deleteJob = (id) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_JOB_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const res = await axios.delete(`${globalLink}/api/job/deleteJob/${id}`, config);

        dispatch({ type: DELETE_JOB_SUCCESS, payload: res });
    } catch (error) {
        dispatch({ type: DELETE_JOB_FAIL, payload: error.response.data.message });
    }
}
// delete JOB::end

// detail JOB::begin
export const detailJob = (id) => async (dispatch) => {
    try {
        dispatch({ type: DETAIL_JOB_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const res = await axios.get(`${globalLink}/api/job/detailJob/${id}`, config);

        dispatch({ type: DETAIL_JOB_SUCCESS, payload: res });
    } catch (error) {
        dispatch({ type: DETAIL_JOB_FAIL, payload: error.response.data.message });
    }
}
// detail JOB::end

// update JOB::begin
export const updateJob = (id, data) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_JOB_REQUEST });

        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        };

        const res = await axios.put(`${globalLink}/api/job/updateJob/${id}`, data, config);

        dispatch({ type: UPDATE_JOB_SUCCESS, payload: res });
    } catch (error) {
        dispatch({ type: UPDATE_JOB_FAIL, payload: error.response.data.message });
    }
}
// update JOB::end

// resets::begin
export const resetNew = () => async (dispatch) => {
    dispatch({ type: RESET_NEW });
};

export const resetUpdate = () => async (dispatch) => {
    dispatch({ type: RESET_UPDATE });
};

export const resetDelete = () => async (dispatch) => {
    dispatch({ type: RESET_DELETE });
};

export const resetDetail = () => async (dispatch) => {
    dispatch({ type: RESET_DETAIL });
};
// resets::end

// clearError::begin
export const clearErrors = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
};
// clearError::end

// create job application::begin
export const createJobApplication = (data) => async (dispatch) => {
    try {
        dispatch({ type: CREATE_JOB_APPLICATION_REQUEST });

        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        };

        const res = await axios.post(`${globalLink}/api/jobApplication/apply/createJobApplication`, data, config);

        dispatch({ type: CREATE_JOB_APPLICATION_SUCCESS, payload: res });
    } catch (error) {
        dispatch({ type: CREATE_JOB_APPLICATION_FAIL, payload: error.response.data.message });
    }
}
// create job application::end


// get job application::begin
export const getJobApplication = () => async (dispatch) => {
    try {
        dispatch({ type: GET_JOB_APPLICATION_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const res = await axios.get(`${globalLink}/api/jobApplication/apply/getJobApplication`, config);

        dispatch({ type: GET_JOB_APPLICATION_SUCCESS, payload: res });
    } catch (error) {
        dispatch({ type: GET_JOB_APPLICATION_FAIL, payload: error.response.data.message });
    }
}
// get job application::end

// delete job application::begin
export const deleteJobApplication = (id) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_JOB_APPLICATION_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const res = await axios.delete(`${globalLink}/api/jobApplication/apply/deleteJobApplication/${id}`, config);

        dispatch({ type: DELETE_JOB_APPLICATION_SUCCESS, payload: res });
    } catch (error) {
        dispatch({ type: DELETE_JOB_APPLICATION_FAIL, payload: error.response.data.message });
    }
}
// delete job application::end

// detail JOB::begin
export const detailJobApplication = (id) => async (dispatch) => {
    try {
        dispatch({ type: DETAIL_JOB_APPLICATION_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const res = await axios.get(`${globalLink}/api/jobApplication/apply/detailJobApplication/${id}`, config);

        dispatch({ type: DETAIL_JOB_APPLICATION_SUCCESS, payload: res });
    } catch (error) {
        dispatch({ type: DETAIL_JOB_APPLICATION_FAIL, payload: error.response.data.message });
    }
}
// detail JOB::end