import React from 'react';
import { Link } from 'react-router-dom';

const Breadcrumb = ({ title }) => {
  return (
    <div className="studymate-breadcrumb">
        <h3><Link to="/">Home</Link> <i className="fa fa-angle-right"></i> {title}</h3>
    </div>
  )
}

export default Breadcrumb