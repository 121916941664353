import {
    GET_SERVICE_REQUEST,
    GET_SERVICE_SUCCESS,
    GET_SERVICE_FAIL,
    CREATE_SERVICE_REQUEST,
    CREATE_SERVICE_SUCCESS,
    CREATE_SERVICE_FAIL,
    UPDATE_SERVICE_REQUEST,
    UPDATE_SERVICE_SUCCESS,
    UPDATE_SERVICE_FAIL,
    DELETE_SERVICE_REQUEST,
    DELETE_SERVICE_SUCCESS,
    DELETE_SERVICE_FAIL,
    DETAIL_SERVICE_REQUEST,
    DETAIL_SERVICE_SUCCESS,
    DETAIL_SERVICE_FAIL,
    CLEAR_ERRORS,
    RESET_NEW,
    RESET_UPDATE,
    RESET_DELETE,
    RESET_DETAIL
} from '../constants/serviceConstant';
import axios from 'axios';

const globalLink = process.env.REACT_APP_API_URL;

// get service::begin
export const getService = (keyword) => async (dispatch) => {
    try {
        dispatch({ type: GET_SERVICE_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const res = await axios.get(`${globalLink}/api/service/getService?keyword=${keyword}`, config);

        dispatch({ type: GET_SERVICE_SUCCESS, payload: res });
    } catch (error) {
        dispatch({ type: GET_SERVICE_FAIL, payload: error.response.data.message });
    }
}
// get service::end

// create service::begin
export const createService = (data) => async (dispatch) => {
    try {
        dispatch({ type: CREATE_SERVICE_REQUEST });

        const config = {
            headers: {
                "Content-Type": "multipart/form-data",   
            }
        };

        const res = await axios.post(`${globalLink}/api/service/createService`, data, config);

        dispatch({ type: CREATE_SERVICE_SUCCESS, payload: res });
    } catch (error) {
        dispatch({ type: CREATE_SERVICE_FAIL, payload: error.response.data.message });
    }
}
// create service::end

// delete service::begin
export const deleteService = (id) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_SERVICE_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",  
            }
        };

        const res = await axios.delete(`${globalLink}/api/service/deleteService/${id}`, config);

        dispatch({ type: DELETE_SERVICE_SUCCESS, payload: res });
    } catch (error) {
        dispatch({ type: DELETE_SERVICE_FAIL, payload: error.response.data.message });
    }
}
// delete service::end

// detail service::begin
export const detailService = (id) => async (dispatch) => {
    try {
        dispatch({ type: DETAIL_SERVICE_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",  
            }
        };

        const res = await axios.get(`${globalLink}/api/service/detailService/${id}`, config);

        dispatch({ type: DETAIL_SERVICE_SUCCESS, payload: res });
    } catch (error) {
        dispatch({ type: DETAIL_SERVICE_FAIL, payload: error.response.data.message });
    }
}
// detail service::end

// update service::begin
export const updateService = (id, data) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_SERVICE_REQUEST });

        const config = {
            headers: {
                "Content-Type": "multipart/form-data", 
            }
        };

        const res = await axios.put(`${globalLink}/api/service/updateService/${id}`, data, config);

        dispatch({ type: UPDATE_SERVICE_SUCCESS, payload: res });
    } catch (error) {
        dispatch({ type: UPDATE_SERVICE_FAIL, payload: error.response.data.message });
    }
}
// update service::end

// resets::begin
export const resetNew = () => async (dispatch) => {
    dispatch({ type: RESET_NEW });
};

export const resetUpdate = () => async (dispatch) => {
    dispatch({ type: RESET_UPDATE });
};

export const resetDelete = () => async (dispatch) => {
    dispatch({ type: RESET_DELETE });
};

export const resetDetail = () => async (dispatch) => {
    dispatch({ type: RESET_DETAIL });
};
// resets::end

// clearError::begin
export const clearErrors = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
};
// clearError::end
