import React from 'react';
import { Link } from 'react-router-dom';

const DashboardBreadcrumb = ({ title }) => {
    return (
        <div className="container-fluid dashboard-breadcrumb">
            <div className="row">
                <div className="col-md-12">
                    <Link to="/dashboard">Dashboard</Link> / <span>{title}</span>
                </div>
            </div>
        </div>
    )
}

export default DashboardBreadcrumb