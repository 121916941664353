import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DashboardBreadcrumb from '../dashboardBreadcrumb/DashboardBreadcrumb';
import CreateUpload from './CreateUpload';
import Notify from '../notify/Notify';
import Conformation from '../conformation/Conformation';
import Loader from '../../../components/loader/Loader';
import { getUpload, deleteUpload, clearErrors, resetDelete } from '../../../state/actions/uploadAction';

const UploadIndex = () => {
    const dispatch = useDispatch();

    const { loading, uploads } = useSelector(state => state.uploads);
    const { error: deleteError, isDeleted, loading: deleteLoading } = useSelector((state) => state.upload);

    const [isMessage, setIsMessage] = useState({
        isThereMessage: false,
        messageType: "",
        message: ""
    });

    const closeMessage = () => {
        return setIsMessage({
            isThereMessage: false,
            messageType: "",
            message: ""
        });
    }

    const [confirmBox, setConfirmBox] = useState(false);
    const [cid, setCid] = useState(null);

    const proceedToConfirm = (id) => {
        setCid(id);
        setConfirmBox(true);
    }

    const proceedToDelete = () => {
        setConfirmBox(false);
        return dispatch(deleteUpload(cid));
    }

    const handleCopy = (copyTxt) => {
        navigator.clipboard.writeText(copyTxt);
        return setIsMessage({
            isThereMessage: true,
            messageType: "success",
            message: "copied to clipboard"
        });
    }

    useEffect(() => {
        if (deleteError) {
            setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: deleteError
            });
            dispatch(clearErrors());
        }

        if (isDeleted && isDeleted !== false) {
            setIsMessage({
                isThereMessage: true,
                messageType: "success",
                message: isDeleted.message
            });
            dispatch(resetDelete());
        }

        dispatch(getUpload());

    }, [dispatch, isDeleted, deleteError]);

    return (
        <>
            {loading || deleteLoading ? <Loader /> : <></>}
            <DashboardBreadcrumb title="Manage Uploads" />
            {
                confirmBox
                    ?
                    <Conformation setConfirmBox={setConfirmBox} proceedFurther={proceedToDelete} message="Hy...Do you want to delete this link?" btnName="DELETE" />
                    :
                    <></>
            }
            <section className="dashboard-operation-section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="dashboard-data-table">
                                {
                                    isMessage.isThereMessage ? <Notify message={isMessage.message} type={isMessage.messageType} closeMessage={closeMessage} /> : <></>
                                }
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Preview</th>
                                            <th>Link</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            uploads && uploads.map((data, index) => (
                                                <tr key={index}>
                                                    <td className="table-image">
                                                        <img src={data.image} draggable={false} alt="table" />
                                                    </td>
                                                    <td className="upload_link">
                                                        <input type="text" value={data.image} />
                                                    </td>
                                                    <td className="table-action">
                                                        <button className="update" onClick={() => handleCopy(data.image)}>Copy Link</button>
                                                        <button className="delete" onClick={() => proceedToConfirm(data.id)}>DELETE</button>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <CreateUpload />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default UploadIndex