import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import img_pre from '../../../assets/images/img-pre.png';
import JoditEditor from 'jodit-react';
import Notify from '../notify/Notify';
import Loader from '../../../components/loader/Loader';
import DashboardBreadcrumb from '../dashboardBreadcrumb/DashboardBreadcrumb';
import { clearErrors, getMdMessage, updateMdMessage, resetUpdate } from '../../../state/actions/mdMessageAction';

const MdMessageIndex = () => {
    const dispatch = useDispatch();

    const { loading, mdMessage, error } = useSelector(state => state.mdMessage);
    const { loading: updateLoading, isUpdated, error: updateError, message: succssMessage } = useSelector(state => state.mdMsg);

    const [isMessage, setIsMessage] = useState({
        isThereMessage: false,
        messageType: "",
        message: ""
    });

    const closeMessage = () => {
        return setIsMessage({
            isThereMessage: false,
            messageType: "",
            message: ""
        });
    }

    const editor = useRef(null);
    const [content, setContent] = useState("");

    const [mdMessageVal, setMdMessageVal] = useState({
        name: "",
        designation: "",
        company_name: "",
    });

    const [file, setFile] = useState(null);

    const onChangeHandler = (e) => {
        setMdMessageVal({ ...mdMessageVal, [e.target.name]: e.target.value });
    }

    const onSubmitHandler = (e) => {
        e.preventDefault();

        if (!mdMessageVal.name || !mdMessageVal.designation || !mdMessageVal.company_name) {
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: "Mandatory field is missing!"
            });
        }

        if (file) {
            if ((file.size / 1024) >= 500) {
                return setIsMessage({
                    isThereMessage: true,
                    messageType: "danger",
                    message: "File size should be less than '500kb'!"
                });
            };
        }

        const myForm = new FormData();
        myForm.append("name", mdMessageVal.name);
        myForm.append("designation", mdMessageVal.designation);
        myForm.append("company_name", mdMessageVal.company_name);
        myForm.append("message", content);

        if (file) {
            myForm.append("image", file);
        }

        return dispatch(updateMdMessage(1, myForm));
    }

    useEffect(() => {
        if (isUpdated) {
            setIsMessage({
                isThereMessage: true,
                messageType: "success",
                message: succssMessage
            });
            dispatch(resetUpdate());
        }

        if (error) {
            setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: error
            });
            dispatch(clearErrors());
        }

        if (updateError) {
            setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: updateError
            });
            dispatch(clearErrors());
        }

        dispatch(getMdMessage());
    }, [dispatch, isUpdated, error, succssMessage, updateError]);

    useEffect(() => {
        setMdMessageVal({
            name: mdMessage.name,
            designation: mdMessage.designation,
            company_name: mdMessage.company_name,
        });
        setContent(mdMessage.message);
    }, [mdMessage]);
    return (
        <>
            {loading || updateLoading ? <Loader /> : <></>}
            <DashboardBreadcrumb title="Manage M.D. Message" />
            {
                isMessage.isThereMessage ? <Notify message={isMessage.message} type={isMessage.messageType} closeMessage={closeMessage} /> : <></>
            }
            <section className="dashboard-operation-section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="dashboard-forms">
                                <h4>UPDATE M.D. MESSAGE</h4>
                                <form>
                                    <div className="input-group">
                                        <label className="dashboard-label">M.D. Name</label>
                                        <input
                                            type="text"
                                            className="dashboard-input"
                                            placeholder="Enter Name"
                                            name="name"
                                            value={mdMessageVal.name}
                                            onChange={(e) => onChangeHandler(e)}
                                        />
                                    </div>
                                    <div className="input-group">
                                        <label className="dashboard-label">Designation</label>
                                        <input
                                            type="text"
                                            className="dashboard-input"
                                            placeholder="Enter Designation"
                                            name="designation"
                                            value={mdMessageVal.designation}
                                            onChange={(e) => onChangeHandler(e)}
                                        />
                                    </div>
                                    <div className="input-group">
                                        <label className="dashboard-label">Company Name</label>
                                        <input
                                            type="text"
                                            className="dashboard-input"
                                            placeholder="Enter Company Name"
                                            name="company_name"
                                            value={mdMessageVal.company_name}
                                            onChange={(e) => onChangeHandler(e)}
                                        />
                                    </div>
                                    <div className="input-group">
                                        <label className="dashboard-label">Message</label>
                                        <JoditEditor
                                            ref={editor}
                                            value={content}
                                            tabIndex={1}
                                            onChange={newContent => setContent(newContent)}
                                        />
                                    </div>
                                    <div className="input-group-type-file">
                                        <label className="dashboard-label">Choose Image <span>(Alert! - File size should be less then "500kb")</span></label>
                                        <input
                                            type="file"
                                            className="dashboard-input-type-file"
                                            accept="image/*"
                                            onChange={(e) => setFile(e.target.files[0])}
                                        />
                                    </div>
                                    <div className="form-img-preview">
                                        <img src={mdMessage && mdMessage.image} draggable={false} alt="md_preview" />
                                    </div>
                                    {
                                        file && file
                                            ?
                                            <div className="form-img-preview">
                                                <img src={URL.createObjectURL(file)} draggable={false} alt="preview" />
                                                <div className="file-remove-btn">
                                                    <button onClick={() => setFile(null)}>REMOVE <i className="fa fa-times"></i></button>
                                                </div>
                                            </div>
                                            :
                                            <div className="form-img-preview">
                                                <img src={img_pre} draggable={false} alt="preview" />
                                            </div>
                                    }
                                    <div className="dashboard-submit-btn100">
                                        <button onClick={onSubmitHandler}>Save Changes</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default MdMessageIndex