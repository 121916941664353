import {
    GET_SERVICE_REQUEST,
    GET_SERVICE_SUCCESS,
    GET_SERVICE_FAIL,
    CREATE_SERVICE_REQUEST,
    CREATE_SERVICE_SUCCESS,
    CREATE_SERVICE_FAIL,
    UPDATE_SERVICE_REQUEST,
    UPDATE_SERVICE_SUCCESS,
    UPDATE_SERVICE_FAIL,
    DELETE_SERVICE_REQUEST,
    DELETE_SERVICE_SUCCESS,
    DELETE_SERVICE_FAIL,
    DETAIL_SERVICE_REQUEST,
    DETAIL_SERVICE_SUCCESS,
    DETAIL_SERVICE_FAIL,
    RESET_NEW,
    RESET_UPDATE,
    RESET_DELETE,
    RESET_DETAIL,
    CLEAR_ERRORS
} from '../constants/serviceConstant';

export const servicesReducer = (state = { services: [] }, action) => {
    switch (action.type) {
        case GET_SERVICE_REQUEST:
            return {
                loading: true,
                services: []
            };

        case GET_SERVICE_SUCCESS:
            return {
                ...state,
                loading: false,
                services: action.payload.data.data
            };

        case GET_SERVICE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};

export const newServiceReducer = (state = { service: {} }, action) => {
    switch (action.type) {
        case CREATE_SERVICE_REQUEST:
            return {
                loading: true,
            };

        case CREATE_SERVICE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: action.payload.data.success,
                message: action.payload.data.message
            };

        case CREATE_SERVICE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        case RESET_NEW:
            return {
                loading: false
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};

export const serviceDetailReducer = (state = { service: {} }, action) => {
    switch (action.type) {
        case DETAIL_SERVICE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case DETAIL_SERVICE_SUCCESS:
            return {
                loading: false,
                service: action.payload.data.data
            };

        case DETAIL_SERVICE_FAIL:
            return {
                loading: false,
                error: action.payload,
            };

        case RESET_DETAIL:
            return {
                loading: false,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};

export const serviceReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_SERVICE_REQUEST:
        case UPDATE_SERVICE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case DELETE_SERVICE_SUCCESS:
            return {
                ...state,
                loading: false,
                isDeleted: action.payload.data.success,
                message: action.payload.data.message
            };

        case UPDATE_SERVICE_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: action.payload.data,
            };

        case DELETE_SERVICE_FAIL:
        case UPDATE_SERVICE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case RESET_UPDATE:
            return {
                ...state,
                isUpdated: false,
            };

        case RESET_DELETE:
            return {
                loading: false,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};
