export const GET_POP_REQUEST = "GET_POP_REQUEST";
export const GET_POP_SUCCESS = "GET_POP_SUCCESS";
export const GET_POP_FAIL = "GET_POP_FAIL";

export const CREATE_POP_REQUEST = "CREATE_POP_REQUEST";
export const CREATE_POP_SUCCESS = "CREATE_POP_SUCCESS";
export const CREATE_POP_FAIL = "CREATE_POP_FAIL";

export const DELETE_POP_REQUEST = "DELETE_POP_REQUEST";
export const DELETE_POP_SUCCESS = "DELETE_POP_SUCCESS";
export const DELETE_POP_FAIL = "DELETE_POP_FAIL";

export const DETAIL_POP_REQUEST = "DETAIL_POP_REQUEST";
export const DETAIL_POP_SUCCESS = "DETAIL_POP_SUCCESS";
export const DETAIL_POP_FAIL = "DETAIL_POP_FAIL";

export const UPDATE_POP_REQUEST = "UPDATE_POP_REQUEST";
export const UPDATE_POP_SUCCESS = "UPDATE_POP_SUCCESS";
export const UPDATE_POP_FAIL = "UPDATE_POP_FAIL";

export const RESET_NEW = "RESET_NEW";
export const RESET_DELETE = "RESET_DELETE";
export const RESET_DETAIL = "RESET_DETAIL";
export const RESET_UPDATE = "RESET_UPDATE";

export const CLEAR_ERRORS = "CLEAR_ERRORS";