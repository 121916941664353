import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import img_pre from '../../../assets/images/img-pre.png';
import Notify from '../notify/Notify';
import { clearErrors, updateTeam, detailTeam, resetDetail, resetUpdate } from '../../../state/actions/teamAction';
import Loader from '../../../components/loader/Loader';
import { useNavigate, useParams } from 'react-router-dom';

const UpdateTeam = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();

    const { loading, team, error } = useSelector(state => state.teamDetail);
    const { loading: updateLoading, isUpdated, error: updateError } = useSelector(state => state.team);

    const [isMessage, setIsMessage] = useState({
        isThereMessage: false,
        messageType: "",
        message: ""
    });

    const closeMessage = () => {
        return setIsMessage({
            isThereMessage: false,
            messageType: "",
            message: ""
        });
    }

    const [teamVal, setTeamVal] = useState({
        name: "",
        designation: "",
        orientation: ""
    });

    const [file, setFile] = useState(null);
    const [oldFile, setOldFile] = useState(null);

    const onChangeHandler = (e) => {
        setTeamVal({ ...teamVal, [e.target.name]: e.target.value });
    }

    const submitHandller = (e) => {
        e.preventDefault();

        if (!teamVal.name || !teamVal.designation || !teamVal.designation) {
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: "Mandatory field is missing!"
            });
        };

        if (file) {
            if ((file.size / 1024) >= 500) {
                return setIsMessage({
                    isThereMessage: true,
                    messageType: "danger",
                    message: "File size should be less than '500kb'!"
                });
            };
        }

        const myForm = new FormData();
        myForm.append("name", teamVal.name);
        myForm.append("designation", teamVal.designation);
        myForm.append("orientation", teamVal.orientation);
        
        if (file) {
            myForm.append("teamImage", file);
        }

        return dispatch(updateTeam(id, myForm));
    }

    useEffect(() => {
        dispatch(detailTeam(id));
        setTeamVal({
            name: team.name,
            designation: team.designation,
            orientation: team.orientation
        });
        setOldFile(team.image);

        if (error) {
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: error
            });
        }

        if (isUpdated) {
            setIsMessage({
                isThereMessage: true,
                messageType: "success",
                message: isUpdated.message
            });
            dispatch(resetDetail());
            dispatch(resetUpdate());
            navigate("/dashboard/manageTeam");
        }

        if (updateError) {
            setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: updateError
            });
            dispatch(clearErrors());
        }
    }, [
        dispatch,
        navigate,
        isUpdated,
        updateError,
        error,
        id,
        team.orientation,
        team.designation,
        team.name,
        team.image,
    ]);

    return (
        <>
            {loading || updateLoading ? <Loader /> : <></>}
            <div className="dashboard-forms">
                <h4>UPDATE TEAM MEMBER</h4>
                {
                    isMessage.isThereMessage ? <Notify message={isMessage.message} type={isMessage.messageType} closeMessage={closeMessage} /> : <></>
                }
                <form>
                    <div className="input-group">
                        <label className="dashboard-label">Name</label>
                        <input
                            type="text"
                            className="dashboard-input"
                            placeholder="Enter product name"
                            name="name"
                            value={teamVal.name}
                            onChange={(e) => onChangeHandler(e)}
                        />
                    </div>
                    <div className="input-group">
                        <label className="dashboard-label">Designation</label>
                        <input
                            type="text"
                            className="dashboard-input"
                            placeholder="Enter member designation"
                            name="designation"
                            value={teamVal.designation}
                            onChange={(e) => onChangeHandler(e)}
                        />
                    </div>
                    <div className="input-group">
                        <label className="dashboard-label">Orientation <span>(Alert! - This will define whiic data come first)</span></label>
                        <input
                            type="number"
                            className="dashboard-input"
                            placeholder="Enter orientation"
                            name="orientation"
                            value={teamVal.orientation}
                            onChange={(e) => onChangeHandler(e)}
                        />
                    </div>
                    <div className="input-group-type-file">
                        <label className="dashboard-label">Image <span>(Alert! - File size should be less than "500kb")</span></label>
                        <input
                            type="file"
                            className="dashboard-input-type-file"
                            accept="image/*"
                            onChange={(e) => setFile(e.target.files[0])}
                        />
                    </div>
                    {
                        file && file
                            ?
                            <div className="form-img-preview">
                                <img src={URL.createObjectURL(file)} draggable={false} alt="preview" />
                                <div className="file-remove-btn">
                                    <button onClick={() => setFile(null)}>REMOVE <i className="fa fa-times"></i></button>
                                </div>
                            </div>
                            :
                            <div className="form-img-preview">
                                <img src={img_pre} draggable={false} alt="preview" />
                            </div>
                    }
                    {
                        oldFile && oldFile
                            ?
                            <div className="form-img-preview">
                                <img src={oldFile} draggable={false} alt="preview" />
                            </div>
                            :
                            <></>
                    }
                    <div className="dashboard-submit-btn100">
                        <button onClick={submitHandller}>UPDATE</button>
                    </div>
                </form>
            </div>

        </>
    )
}

export default UpdateTeam;