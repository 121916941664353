import {
    GET_JOB_REQUEST,
    GET_JOB_SUCCESS,
    GET_JOB_FAIL,
    CREATE_JOB_REQUEST,
    CREATE_JOB_SUCCESS,
    CREATE_JOB_FAIL,
    UPDATE_JOB_REQUEST,
    UPDATE_JOB_SUCCESS,
    UPDATE_JOB_FAIL,
    DELETE_JOB_REQUEST,
    DELETE_JOB_SUCCESS,
    DELETE_JOB_FAIL,
    DETAIL_JOB_REQUEST,
    DETAIL_JOB_SUCCESS,
    DETAIL_JOB_FAIL,
    RESET_NEW,
    RESET_UPDATE,
    RESET_DELETE,
    RESET_DETAIL,
    CLEAR_ERRORS,
    GET_JOB_APPLICATION_REQUEST,
    GET_JOB_APPLICATION_SUCCESS,
    GET_JOB_APPLICATION_FAIL,
    CREATE_JOB_APPLICATION_REQUEST,
    CREATE_JOB_APPLICATION_SUCCESS,
    CREATE_JOB_APPLICATION_FAIL,
    DELETE_JOB_APPLICATION_REQUEST,
    DELETE_JOB_APPLICATION_SUCCESS,
    DELETE_JOB_APPLICATION_FAIL,
    DETAIL_JOB_APPLICATION_REQUEST,
    DETAIL_JOB_APPLICATION_SUCCESS,
    DETAIL_JOB_APPLICATION_FAIL,
} from '../constants/jobConstant';

export const jobsReducer = (state = { jobApplications: [] }, action) => {
    switch (action.type) {
        case GET_JOB_REQUEST:
            return {
                loading: true,
                jobs: []
            };

        case GET_JOB_SUCCESS:
            return {
                ...state,
                loading: false,
                jobs: action.payload.data.data
            };

        case GET_JOB_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};

export const newJobReducer = (state = { job: {} }, action) => {
    switch (action.type) {
        case CREATE_JOB_REQUEST:
            return {
                loading: true,
            };

        case CREATE_JOB_SUCCESS:
            return {
                ...state,
                loading: false,
                success: action.payload.data.success,
                message: action.payload.data.message
            };

        case CREATE_JOB_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        case RESET_NEW:
            return {
                loading: false
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};

export const jobDetailReducer = (state = { job: {} }, action) => {
    switch (action.type) {
        case DETAIL_JOB_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case DETAIL_JOB_SUCCESS:
            return {
                loading: false,
                job: action.payload.data.data
            };

        case DETAIL_JOB_FAIL:
            return {
                loading: false,
                error: action.payload,
            };

        case RESET_DETAIL:
            return {
                loading: false,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};

export const jobReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_JOB_REQUEST:
        case UPDATE_JOB_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case DELETE_JOB_SUCCESS:
            return {
                ...state,
                loading: false,
                isDeleted: action.payload.data.success,
                message: action.payload.data.message
            };

        case UPDATE_JOB_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: action.payload.data,
            };

        case DELETE_JOB_FAIL:
        case UPDATE_JOB_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case RESET_UPDATE:
            return {
                ...state,
                isUpdated: false,
            };

        case RESET_DELETE:
            return {
                loading: false,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};

// ----------------------------
export const jobApplicationsReducer = (state = { jobsApplication: [] }, action) => {
    switch (action.type) {
        case GET_JOB_APPLICATION_REQUEST:
            return {
                loading: true,
                jobsApplication: []
            };

        case GET_JOB_APPLICATION_SUCCESS:
            return {
                ...state,
                loading: false,
                jobsApplication: action.payload.data.data
            };

        case GET_JOB_APPLICATION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};


export const newJobApplicationReducer = (state = { jobApplication: {} }, action) => {
    switch (action.type) {
        case CREATE_JOB_APPLICATION_REQUEST:
            return {
                loading: true,
            };

        case CREATE_JOB_APPLICATION_SUCCESS:
            return {
                ...state,
                loading: false,
                success: action.payload.data.success,
                message: action.payload.data.message
            };

        case CREATE_JOB_APPLICATION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        case RESET_NEW:
            return {
                loading: false
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};

export const jobApplicationReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_JOB_APPLICATION_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case DELETE_JOB_APPLICATION_SUCCESS:
            return {
                ...state,
                loading: false,
                isDeleted: action.payload.data.success,
                message: action.payload.data.message
            };

        case DELETE_JOB_APPLICATION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case RESET_DELETE:
            return {
                loading: false,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};

export const jobApplicationDetailReducer = (state = { job: {} }, action) => {
    switch (action.type) {
        case DETAIL_JOB_APPLICATION_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case DETAIL_JOB_APPLICATION_SUCCESS:
            return {
                loading: false,
                job: action.payload.data.data
            };

        case DETAIL_JOB_APPLICATION_FAIL:
            return {
                loading: false,
                error: action.payload,
            };

        case RESET_DETAIL:
            return {
                loading: false,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};