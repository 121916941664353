export const GET_JOB_REQUEST = "GET_JOB_REQUEST";
export const GET_JOB_SUCCESS = "GET_JOB_SUCCESS";
export const GET_JOB_FAIL = "GET_JOB_FAIL";

export const CREATE_JOB_REQUEST = "CREATE_JOB_REQUEST";
export const CREATE_JOB_SUCCESS = "CREATE_JOB_SUCCESS";
export const CREATE_JOB_FAIL = "CREATE_JOB_FAIL";

export const DELETE_JOB_REQUEST = "DELETE_JOB_REQUEST";
export const DELETE_JOB_SUCCESS = "DELETE_JOB_SUCCESS";
export const DELETE_JOB_FAIL = "DELETE_JOB_FAIL";

export const DETAIL_JOB_REQUEST = "DETAIL_JOB_REQUEST";
export const DETAIL_JOB_SUCCESS = "DETAIL_JOB_SUCCESS";
export const DETAIL_JOB_FAIL = "DETAIL_JOB_FAIL";

export const UPDATE_JOB_REQUEST = "UPDATE_JOB_REQUEST";
export const UPDATE_JOB_SUCCESS = "UPDATE_JOB_SUCCESS";
export const UPDATE_JOB_FAIL = "UPDATE_JOB_FAIL";

export const RESET_NEW = "RESET_NEW";
export const RESET_DELETE = "RESET_DELETE";
export const RESET_DETAIL = "RESET_DETAIL";
export const RESET_UPDATE = "RESET_UPDATE";

export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const GET_JOB_APPLICATION_REQUEST = "GET_JOB_APPLICATION_REQUEST";
export const GET_JOB_APPLICATION_SUCCESS = "GET_JOB_APPLICATION_SUCCESS";
export const GET_JOB_APPLICATION_FAIL = "GET_JOB_APPLICATION_FAIL";

export const CREATE_JOB_APPLICATION_REQUEST = "CREATE_JOB_APPLICATION_REQUEST";
export const CREATE_JOB_APPLICATION_SUCCESS = "CREATE_JOB_APPLICATION_SUCCESS";
export const CREATE_JOB_APPLICATION_FAIL = "CREATE_JOB_APPLICATION_FAIL";

export const DELETE_JOB_APPLICATION_REQUEST = "DELETE_JOB_APPLICATION_REQUEST";
export const DELETE_JOB_APPLICATION_SUCCESS = "DELETE_JOB_APPLICATION_SUCCESS";
export const DELETE_JOB_APPLICATION_FAIL = "DELETE_JOB_APPLICATION_FAIL";

export const DETAIL_JOB_APPLICATION_REQUEST = "DETAIL_JOB_APPLICATION_REQUEST";
export const DETAIL_JOB_APPLICATION_SUCCESS = "DETAIL_JOB_APPLICATION_SUCCESS";
export const DETAIL_JOB_APPLICATION_FAIL = "DETAIL_JOB_APPLICATION_FAIL";