import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getService } from '../../state/actions/serviceAction';
import Loading from '../../components/loader/Loader';
import ScrollTrigger from 'react-scroll-trigger';

const StudyAbroad = () => {
    const dispatch = useDispatch();

    const { loading, services } = useSelector(state => state.services);

    const [counterOn, setCounterOn] = useState(false);

    useEffect(() => {
        dispatch(getService("all"));
    }, [dispatch]);
    return (
        <>
            {loading ? <Loading /> : <></>}
            <section className="stud-abroad-section">
                <div className="studymate-header studymate-header-center">
                    <h4>WHERE DO YOU WANT TO STUDY?</h4>
                </div>
                <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                    <div className="container">
                        <div className="row">
                            {
                                services && services.filter(opt => opt.category === "Study Abroad").map((data, index) => (
                                    <div className="col-md-3" key={index}>
                                        <Link to={`/serviceDetail/${data.id}`}>
                                            <div className={counterOn ? "study-abroad-card fadeFromLeftAltt" : "study-abroad-card"} style={{ "--i": index }}>
                                                <div className="abroad-study-image-wrapper">
                                                    <img src={data.image} alt="featured" />
                                                </div>
                                                <div className="overlay"></div>
                                                <div className="abroad-study-title">
                                                    <h3>{data.title}</h3>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </ScrollTrigger>
            </section>
        </>
    )
}

export default StudyAbroad