import {
    GET_MD_MESSAGE_REQUEST,
    GET_MD_MESSAGE_SUCCESS,
    GET_MD_MESSAGE_FAIL,
    UPDATE_MD_MESSAGE_REQUEST,
    UPDATE_MD_MESSAGE_SUCCESS,
    UPDATE_MD_MESSAGE_FAIL,
    RESET_UPDATE,
    CLEAR_ERRORS,
} from '../constants/mdMessageConstant';
import axios from 'axios';

const globalLink = process.env.REACT_APP_API_URL;

// get md message::begin
export const getMdMessage = () => async (dispatch) => {
    try {
        dispatch({ type: GET_MD_MESSAGE_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const res = await axios.get(`${globalLink}/api/message/getMessage`, config);

        dispatch({ type: GET_MD_MESSAGE_SUCCESS, payload: res });
    } catch (error) {
        dispatch({ type: GET_MD_MESSAGE_FAIL, payload: error.response.data.message });
    }
}
// get md message::end

// update message::begin
export const updateMdMessage = (id, data) => async (dispatch) => {
    // console.log([...data])
    try {
        dispatch({ type: UPDATE_MD_MESSAGE_REQUEST });

        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        };

        const res = await axios.put(`${globalLink}/api/message/updateMessage/${id}`, data, config);

        dispatch({ type: UPDATE_MD_MESSAGE_SUCCESS, payload: res });
    } catch (error) {
        dispatch({ type: UPDATE_MD_MESSAGE_FAIL, payload: error.response.data.message });
    }
}
// update md message::end

// resets::begin
export const resetUpdate = () => async (dispatch) => {
    dispatch({ type: RESET_UPDATE });
};

// clearError::begin
export const clearErrors = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
};
// clearError::end
