import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import DashboardBreadcrumb from '../dashboardBreadcrumb/DashboardBreadcrumb';
import Loader from '../../../components/loader/Loader';
import { detailJobApplication, resetDetail } from '../../../state/actions/jobAction';
import moment from 'moment';

const ViewJobApplication = () => {
    const { id } = useParams();
    const dispatch = useDispatch();

    const { job, loading } = useSelector(state => state.jobApplicationDetail);

    useEffect(() => {
        dispatch(detailJobApplication(id));

        return () => {
            dispatch(resetDetail());
        }
    }, [dispatch, id]);
    return (
        <>
            {loading ? <Loader /> : <></>}
            <DashboardBreadcrumb title="Vacancy Detail" />
            <div className="dashboard-detail-page">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-10">
                            <div className="dashboard-detail-page-wrapper">
                                <div className="dashboard-detail-page-parent">
                                    <div className="dashboard-detail-page-childs">
                                        <p>Applicant Name -</p>
                                    </div>
                                    <div className="dashboard-detail-page-childs">
                                        <p>{job && job.name}</p>
                                    </div>
                                </div>
                                <div className="dashboard-detail-page-parent">
                                    <div className="dashboard-detail-page-childs">
                                        <p>Email -</p>
                                    </div>
                                    <div className="dashboard-detail-page-childs">
                                        <p>{job && job.email}</p>
                                    </div>
                                </div>
                                <div className="dashboard-detail-page-parent">
                                    <div className="dashboard-detail-page-childs">
                                        <p>Phone -</p>
                                    </div>
                                    <div className="dashboard-detail-page-childs">
                                        <p>{job && job.phone}</p>
                                    </div>
                                </div>
                                <div className="dashboard-detail-page-parent">
                                    <div className="dashboard-detail-page-childs">
                                        <p>Address -</p>
                                    </div>
                                    <div className="dashboard-detail-page-childs">
                                        <p>{job && job.address}</p>
                                    </div>
                                </div>
                                <div className="dashboard-detail-page-parent">
                                    <div className="dashboard-detail-page-childs">
                                        <p>Experience -</p>
                                    </div>
                                    <div className="dashboard-detail-page-childs">
                                        <p>{job && job.experience}</p>
                                    </div>
                                </div>
                                <div className="dashboard-detail-page-parent">
                                    <div className="dashboard-detail-page-childs">
                                        <p>Applied For -</p>
                                    </div>
                                    <div className="dashboard-detail-page-childs">
                                        <p>{job && job.applyfor}</p>
                                    </div>
                                </div>
                                <div className="dashboard-detail-page-parent">
                                    <div className="dashboard-detail-page-childs">
                                        <p>Cover Letter -</p>
                                    </div>
                                    <div className="dashboard-detail-page-childs">
                                        <p>{job && job.coverletter}</p>
                                    </div>
                                </div>
                                <div className="dashboard-detail-page-parent">
                                    <div className="dashboard-detail-page-childs">
                                        <p>Date -</p>
                                    </div>
                                    <div className="dashboard-detail-page-childs">
                                        <p>{job && moment(job.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</p>
                                    </div>
                                </div>
                                <div className="download-cv-btn">
                                    <a href={job && job.resume} target="_blank" rel="noreferrer">Download CV/Resume</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ViewJobApplication;