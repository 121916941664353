import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { detailService } from '../../state/actions/serviceAction';
import Loader from '../../components/loader/Loader';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import Application from './Application';

const ServiceDetail = () => {
    const { id } = useParams();
    const dispatch = useDispatch();

    const { loading, service } = useSelector(state => state.serviceDetail);

    useEffect(() => {
        dispatch(detailService(id));
    }, [id, dispatch]);

    return (
        <>
            {loading ? <Loader /> : <></>}
            <section className="service-detail-page-section">
                <Breadcrumb title={ service && service.title } />
                <div className="container mt-4">
                    <div className="row">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="service-detail-image">
                                    <img src={service && service.image} alt="detail" draggable={false} />
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="detail-container">
                                    <div dangerouslySetInnerHTML={{ __html: service && service.description }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Application />
        </>
    )
}

export default ServiceDetail;