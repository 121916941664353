import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPop } from '../../state/actions/popAction';
import moment from 'moment';

const Notification = () => {
    const dispatch = useDispatch();

    const { pops } = useSelector((state) => state.pops) || [];
    const totalNotices = pops.length - 1;

    const [isNotice, setIsNotice] = useState(true);
    const [showIndex, setShowIndex] = useState(0);

    const closeNotice = () => {
        if (totalNotices !== showIndex) {
            return setShowIndex(showIndex + 1);
        }
        return setIsNotice(false);
    }

    useEffect(() => {
        dispatch(getPop("visible"));
    }, [dispatch]);

    return (
        <>
            {
                pops && pops.length === 0
                    ?
                    <></>
                    :
                    <>
                        {
                            isNotice
                                ?
                                <div className="popup-notification-wrapper">
                                    <div className="popup-notification-inner">
                                        <div className="notification-header">
                                            <div className="childs">
                                                <h3>Publish Date: {pops && pops[showIndex] && moment(pops[showIndex].createdAt).format('D MMMM YYYY, h:mm:ss a')}</h3>
                                            </div>
                                            <div className="childs">
                                                <button onClick={() => closeNotice()}><i className="fa fa-times"></i></button>
                                            </div>
                                        </div>
                                        <div className="notification-img">
                                            <img src={pops && pops[showIndex] && pops[showIndex].image} alt="studymate-notification" />
                                        </div>
                                    </div>
                                </div>
                                :
                                <></>
                        }
                    </>
            }
        </>
    )
}

export default Notification;