import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Notify from '../notify/Notify';
import img_pre from '../../../assets/images/img-pre.png';
import JoditEditor from 'jodit-react';
import Loader from '../../../components/loader/Loader';
import { createJob, getJob, resetNew } from '../../../state/actions/jobAction';

const CreateJob = () => {
    const dispatch = useDispatch();

    const { success, loading, error, message: successMessage } = useSelector(state => state.newJob);

    const [isMessage, setIsMessage] = useState({
        isThereMessage: false,
        messageType: "",
        message: ""
    });

    const closeMessage = () => {
        return setIsMessage({
            isThereMessage: false,
            messageType: "",
            message: ""
        });
    }

    const editor = useRef(null);
    const [content, setContent] = useState("");
    const [file, setFile] = useState(null);

    const [jobVal, setJobVal] = useState({
        title: "",
        designation: "",
        jobType: "",
        location: "",
        orientation: "",
        visibility: ""
    });

    const onChangeHandler = (e) => {
        setJobVal({ ...jobVal, [e.target.name]: e.target.value });
    }

    const submitHandller = (e) => {
        e.preventDefault();

        if (!jobVal.title || !jobVal.designation || !jobVal.jobType || !jobVal.location || !jobVal.orientation || !jobVal.visibility) {
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: "Mandatory field is missing!"
            });
        };

        if (file === null) {
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: "Please choose thumbnail!"
            });
        }

        if ((file.size / 1024) >= 500) {
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: "File size should be less than '500kb'!"
            });
        };

        const myForm = new FormData();
        myForm.append("title", jobVal.title);
        myForm.append("description", content);
        myForm.append("designation", jobVal.designation);
        myForm.append("jobtype", jobVal.jobType);
        myForm.append("location", jobVal.location);
        myForm.append("orientation", jobVal.orientation);
        myForm.append("visibility", jobVal.visibility);
        myForm.append("image", file);

        return dispatch(createJob(myForm));
    }

    useEffect(() => {
        if (error) {
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: error
            });
        }

        if (success) {
            setIsMessage({
                isThereMessage: true,
                messageType: "success",
                message: successMessage
            });
            dispatch(resetNew());
            dispatch(getJob());
            setFile(null);
            setJobVal({
                title: "",
                designation: "",
                jobType: "",
                location: "",
                orientation: "",
                visibility: ""
            });
            setContent("");
        }
    }, [dispatch, success, error, successMessage]);

    return (
        <>
            {loading ? <Loader /> : <></>}
            <div className="dashboard-forms">
                <h4>ADD JOB VACANCY</h4>
                {
                    isMessage.isThereMessage ? <Notify message={isMessage.message} type={isMessage.messageType} closeMessage={closeMessage} /> : <></>
                }
                <form>
                    <div className="input-group">
                        <label className="dashboard-label">Title</label>
                        <input
                            type="text"
                            className="dashboard-input"
                            placeholder="Enter title"
                            name="title"
                            value={jobVal.title}
                            onChange={(e) => onChangeHandler(e)}
                        />
                    </div>
                    <div className="input-group">
                        <label className="dashboard-label">Designation</label>
                        <input
                            type="text"
                            className="dashboard-input"
                            placeholder="Enter job designation"
                            name="designation"
                            value={jobVal.designation}
                            onChange={(e) => onChangeHandler(e)}
                        />
                    </div>
                    <div className="input-group">
                        <label className="dashboard-label">Job Type</label>
                        <select className="dashboard-input"
                            name="jobType"
                            value={jobVal.jobType}
                            onChange={(e) => onChangeHandler(e)}
                        >
                            <option>- Select Job Type -</option>
                            <option value="Full Time">Full Time</option>
                            <option value="Part Time">Part Time</option>
                        </select>
                    </div>
                    <div className="input-group">
                        <label className="dashboard-label">Location</label>
                        <input
                            type="text"
                            className="dashboard-input"
                            placeholder="Enter location"
                            name="location"
                            value={jobVal.location}
                            onChange={(e) => onChangeHandler(e)}
                        />
                    </div>
                    <div className="input-group">
                        <label className="dashboard-label">Orientation <span>(Alert! - This will define whiic data come first)</span></label>
                        <input
                            type="number"
                            className="dashboard-input"
                            placeholder="Enter orientation"
                            name="orientation"
                            value={jobVal.orientation}
                            onChange={(e) => onChangeHandler(e)}
                        />
                    </div>
                    <div className="input-group">
                        <label className="dashboard-label">Visibility</label>
                        <select className="dashboard-input"
                            name="visibility"
                            value={jobVal.visibility}
                            onChange={(e) => onChangeHandler(e)}
                        >
                            <option>- Select Visibility -</option>
                            <option value="visible">Visible</option>
                            <option value="hide">Hide</option>
                        </select>
                    </div>
                    <div className="input-group">
                        <label className="dashboard-label">Description</label>
                        <JoditEditor
                            ref={editor}
                            value={content}
                            tabIndex={1}
                            onChange={newContent => setContent(newContent)}
                        />
                    </div>
                    <div className="input-group-type-file">
                        <label className="dashboard-label">Choose Image <span>(Alert! - File size should be less than "500kb")</span></label>
                        <input
                            type="file"
                            className="dashboard-input-type-file"
                            accept="image/*"
                            onChange={(e) => setFile(e.target.files[0])}
                        />
                    </div>
                    {
                        file && file
                            ?
                            <div className="form-img-preview">
                                <img src={URL.createObjectURL(file)} draggable={false} alt="preview" />
                                <div className="file-remove-btn">
                                    <button onClick={() => setFile(null)}>REMOVE <i className="fa fa-times"></i></button>
                                </div>
                            </div>
                            :
                            <div className="form-img-preview">
                                <img src={img_pre} draggable={false} alt="preview" />
                            </div>
                    }
                    <div className="dashboard-submit-btn100">
                        <button onClick={submitHandller}>ADD</button>
                    </div>
                </form>
            </div>

        </>
    )
}

export default CreateJob;