import React, { useEffect } from 'react';
import './notification.css';

const Notification = ({ message, type, closeError }) => {

    useEffect(()=> {
        const interval = setTimeout(()=> {
            return closeError();
        }, [3000]);

        return () => {
            clearInterval(interval);
        }
    }, [message, type, closeError]);
    return (
        <div className={`studymate-notification-wrapper ${type}`}>
            <h4>{message}</h4>
            <div className="close-btn">
                <i className="fa fa-times" onClick={closeError}></i>
            </div>
        </div>
    )
}

export default Notification