import {
    CREATE_SLIDER_REQUEST,
    CREATE_SLIDER_SUCCESS,
    CREATE_SLIDER_FAIL,
    GET_SLIDER_REQUEST,
    GET_SLIDER_SUCCESS,
    GET_SLIDER_FAIL,
    DELETE_SLIDER_REQUEST,
    DELETE_SLIDER_SUCCESS,
    DELETE_SLIDER_FAIL,
    CLEAR_ERRORS,
    RESET_NEW,
    RESET_DELETE,
} from '../constants/sliderConstant';
import axios from 'axios';

const globalLink = process.env.REACT_APP_API_URL;

// get slider::begin
export const getSlider = () => async (dispatch) => {
    try {
        dispatch({ type: GET_SLIDER_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const res = await axios.get(`${globalLink}/api/slider/getSlider`, config);

        dispatch({ type: GET_SLIDER_SUCCESS, payload: res });
    } catch (error) {
        dispatch({ type: GET_SLIDER_FAIL, payload: error.response.data.message });
    }
}
// get slider::end

// create slider::begin
export const createSlider = (data) => async (dispatch) => {
    try {
        dispatch({ type: CREATE_SLIDER_REQUEST });

        const config = {
            headers: {
                "Content-Type": "multipart/form-data",   
            }
        };

        const res = await axios.post(`${globalLink}/api/slider/createSlider`, data, config);

        dispatch({ type: CREATE_SLIDER_SUCCESS, payload: res });
    } catch (error) {
        dispatch({ type: CREATE_SLIDER_FAIL, payload: error.response.data.message });
    }
}
// create slider::end

// delete slider::begin
export const deleteSlider = (id) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_SLIDER_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const res = await axios.delete(`${globalLink}/api/slider/deleteSlider/${id}`, config);

        dispatch({ type: DELETE_SLIDER_SUCCESS, payload: res });
    } catch (error) {
        dispatch({ type: DELETE_SLIDER_FAIL, payload: error.response.data.message });
    }
}
// delete slider::end

// resets::begin
export const resetNew = () => async (dispatch) => {
    dispatch({ type: RESET_NEW });
};

export const resetDelete = () => async (dispatch) => {
    dispatch({ type: RESET_DELETE });
};

// resets::end

// clearError::begin
export const clearErrors = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
};
// clearError::end