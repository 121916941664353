export const GET_GALLERY_REQUEST = "GET_GALLERY_REQUEST";
export const GET_GALLERY_SUCCESS = "GET_GALLERY_SUCCESS";
export const GET_GALLERY_FAIL = "GET_GALLERY_FAIL";

export const CREATE_GALLERY_REQUEST = "CREATE_GALLERY_REQUEST";
export const CREATE_GALLERY_SUCCESS = "CREATE_GALLERY_SUCCESS";
export const CREATE_GALLERY_FAIL = "CREATE_GALLERY_FAIL";

export const DELETE_GALLERY_REQUEST = "DELETE_GALLERY_REQUEST";
export const DELETE_GALLERY_SUCCESS = "DELETE_GALLERY_SUCCESS";
export const DELETE_GALLERY_FAIL = "DELETE_GALLERY_FAIL";

export const RESET_NEW = "RESET_NEW";
export const RESET_DELETE = "RESET_DELETE";
export const RESET_DETAIL = "RESET_DETAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";