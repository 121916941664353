import React from 'react'

const Branch = () => {
    return (
        <div className="text-center mt-4 mb-4">
            <h4>Branch Details</h4>
        </div>
    )
}

export default Branch