import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Notify from '../notify/Notify';
import img_pre from '../../../assets/images/img-pre.png';
import Loader from '../../../components/loader/Loader';
import { createSlider, getSlider, resetNew } from '../../../state/actions/sliderAction';

const CreateSlider = () => {
    const dispatch = useDispatch();

    const { success, loading, error, message: successMessage } = useSelector(state => state.newSlider);

    const [isMessage, setIsMessage] = useState({
        isThereMessage: false,
        messageType: "",
        message: ""
    });

    const closeMessage = () => {
        return setIsMessage({
            isThereMessage: false,
            messageType: "",
            message: ""
        });
    }

    const [orientation, setOrientation] = useState("");
    const [file, setFile] = useState(null);

    const submitHandller = (e) => {
        e.preventDefault();

        if (!orientation) {
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: "Please provide orientation!"
            });
        }

        if (file === null) {
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: "Please choose file!"
            });
        }

        if ((file.size / 1024) >= 500) {
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: "File size should be less than '500kb'!"
            });
        };

        const myForm = new FormData();
        myForm.append("orientation", orientation);
        myForm.append("image", file);

        dispatch(createSlider(myForm));
    }

    useEffect(() => {
        if (error) {
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: error
            });
        }

        if (success) {
            setIsMessage({
                isThereMessage: true,
                messageType: "success",
                message: successMessage
            });
            dispatch(resetNew());
            dispatch(getSlider());
            setFile(null);
            setOrientation("");
        }
    }, [dispatch, success, error, successMessage]);

    return (
        <>
            {loading ? <Loader /> : <></>}
            <div className="dashboard-forms">
                <h4>ADD SLIDER</h4>
                {
                    isMessage.isThereMessage ? <Notify message={isMessage.message} type={isMessage.messageType} closeMessage={closeMessage} /> : <></>
                }
                <form>
                    <div className="input-group">
                        <label className="dashboard-label">Orientation</label>
                        <input
                            type="number"
                            className="dashboard-input"
                            placeholder="Enter orientation"
                            name="orientation"
                            value={orientation}
                            onChange={(e) => setOrientation(e.target.value)}
                        />
                    </div>
                    <div className="input-group-type-file">
                        <label className="dashboard-label">Slider Image <span>(Alert! - File size should be less than "500kb". Please use "804px X 528px" image dimension for the perfect match!")</span></label>
                        <input
                            type="file"
                            className="dashboard-input-type-file"
                            accept="image/*"
                            onChange={(e) => setFile(e.target.files[0])}
                        />
                    </div>
                    {
                        file && file
                            ?
                            <div className="form-img-preview">
                                <img src={URL.createObjectURL(file)} draggable={false} alt="preview" />
                                <div className="file-remove-btn">
                                    <button onClick={() => setFile(null)}>REMOVE <i className="fa fa-times"></i></button>
                                </div>
                            </div>
                            :
                            <div className="form-img-preview">
                                <img src={img_pre} draggable={false} alt="preview" />
                            </div>
                    }
                    <div className="dashboard-submit-btn100">
                        <button onClick={submitHandller}>ADD</button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default CreateSlider