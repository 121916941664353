import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import studyMateLogo from '../../assets/images/studyMateLogo.png';
import Notification from '../../components/notification/Notification';
import Loader from '../../components/loader/Loader';
import { clearErrors, login } from '../../state/actions/userAction';

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { isAuthenticated, loading, error: loginError } = useSelector((state) => state.users);

    const [userDetail, setUserDetail] = useState({
        email: "",
        password: "",
    });

    const [isError, setIsError] = useState({
        isThereError: false,
        errorMessage: "",
        errorType: "",
    });

    const onchangeHander = (e) => {
        setUserDetail({ ...userDetail, [e.target.name]: e.target.value });
    }

    const sumbitHandler = (e) => {
        e.preventDefault();
        if (!userDetail.email) {
            return setIsError({
                isThereError: true,
                errorMessage: "Please provide email address!",
                errorType: "danger",
            });
        }

        if (!userDetail.password) {
            return setIsError({
                isThereError: true,
                errorMessage: "Please enter password!",
                errorType: "danger",
            });
        }

        if (userDetail.email.includes("@gmail.com") === false) {
            return setIsError({
                isThereError: true,
                errorMessage: "Email address is invalid, try again",
                errorType: "danger",
            });
        }

        dispatch(login(userDetail.email, userDetail.password));
    }

    const closeError = () => {
        return setIsError({
            isThereError: false,
            errorMessage: "",
            errorType: "",
        });
    }

    useEffect(() => {
        if (loginError) {
            setIsError({
                isThereError: true,
                errorMessage: loginError,
                errorType: "danger",
            });
            dispatch(clearErrors());
        }

        if(isAuthenticated) {
            return navigate("/dashboard");   
        }
    }, [dispatch, loginError, isAuthenticated, navigate]);

    return (
        <>
            {loading ? <Loader /> : <></>}
            <section className="auth-section">
                <div className="auth-wrapper">
                    <div className="auth-inner-wrapper">
                        <div className="logo-auth">
                            <Link to="/">
                                <img src={studyMateLogo} alt="studymate_logo" draggable={false} />
                            </Link>
                        </div>
                        <h3>LOGIN</h3>
                        <form className="global-form">
                            {
                                isError.isThereError
                                    ?
                                    <Notification message={isError.errorMessage} type={isError.errorType} closeError={closeError} />
                                    :
                                    <></>

                            }
                            <div className="input-group">
                                <label className="global-label">Email</label>
                                <input
                                    type="email"
                                    placeholder="Enter Email Address"
                                    className="global-input"
                                    name="email"
                                    value={userDetail.email}
                                    onChange={(e) => onchangeHander(e)}
                                />
                            </div>
                            <div className="input-group">
                                <label className="global-label">Password</label>
                                <input
                                    type="password"
                                    placeholder="Enter Password"
                                    className="global-input"
                                    name="password"
                                    value={userDetail.password}
                                    onChange={(e) => onchangeHander(e)}
                                />
                            </div>
                            {/* <div className="input-group">
                                <input className="show-hide-check" type="checkbox" /><span className="form-danger-info">Show Password</span>
                            </div> */}
                            <div className="submit-btn">
                                <button onClick={sumbitHandler}>Login</button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Login;