import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import DashboardBreadcrumb from '../dashboardBreadcrumb/DashboardBreadcrumb';
import Loader from '../../../components/loader/Loader';
import { resetDetail, detailService } from '../../../state/actions/serviceAction';

const ViewService = () => {
    const { id } = useParams();
    const dispatch = useDispatch();

    const { service, loading } = useSelector(state => state.serviceDetail);

    useEffect(() => {
        dispatch(detailService(id));

        return () => {
            dispatch(resetDetail());
        }
    }, [dispatch, id]);
    return (
        <>
            {loading ? <Loader /> : <></>}
            <DashboardBreadcrumb title="Service Detail" />
            <div className="dashboard-detail-page">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-10">
                            <div className="dashboard-detail-page-wrapper">
                                <div className="dashboard-detail-page-parent">
                                    <div className="dashboard-detail-page-childs">
                                        <p>product_id -</p>
                                    </div>
                                    <div className="dashboard-detail-page-childs">
                                        <p>{service && service.id}</p>
                                    </div>
                                </div>
                                <div className="dashboard-detail-page-parent">
                                    <div className="dashboard-detail-page-childs">
                                        <p>Title -</p>
                                    </div>
                                    <div className="dashboard-detail-page-childs">
                                        <p>{service && service.title}</p>
                                    </div>
                                </div>
                                <div className="dashboard-detail-page-parent">
                                    <div className="dashboard-detail-page-childs">
                                        <p>Orientation -</p>
                                    </div>
                                    <div className="dashboard-detail-page-childs">
                                        <p>{service && service.orientation}</p>
                                    </div>
                                </div>
                                <div className="dashboard-detail-page-parent">
                                    <div className="dashboard-detail-page-childs">
                                        <p>Category -</p>
                                    </div>
                                    <div className="dashboard-detail-page-childs">
                                        <p>{service && service.orientation}</p>
                                    </div>
                                </div>
                                <div className="dashboard-detail-page-parent">
                                    <div className="dashboard-detail-page-childs">
                                        <p>Thumbnail -</p>
                                    </div>
                                    <div className="dashboard-detail-page-childs">
                                        <ul>
                                            <li><img src={service && service.image} alt="thumbnail" draggable={false} /></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="dashboard-detail-page-parent">
                                    <div className="dashboard-detail-page-childs">
                                        <p>Description -</p>
                                    </div>
                                    <div className="dashboard-detail-page-childs">
                                        <div dangerouslySetInnerHTML={{ __html: service && service.description }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ViewService;