import {
    CREATE_GALLERY_REQUEST,
    CREATE_GALLERY_SUCCESS,
    CREATE_GALLERY_FAIL,
    GET_GALLERY_REQUEST,
    GET_GALLERY_SUCCESS,
    GET_GALLERY_FAIL,
    DELETE_GALLERY_REQUEST,
    DELETE_GALLERY_SUCCESS,
    DELETE_GALLERY_FAIL,
    CLEAR_ERRORS,
    RESET_NEW,
    RESET_DELETE,
} from '../constants/galleryConstant';

export const gallerysReducer = (state = { gallerys: [] }, action) => {
    switch (action.type) {
        case GET_GALLERY_REQUEST:
            return {
                loading: true,
                gallerys: []
            };

        case GET_GALLERY_SUCCESS:
            return {
                ...state,
                loading: false,
                gallerys: action.payload.data.data
            };

        case GET_GALLERY_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};

export const newGalleryReducer = (state = { gallery: {} }, action) => {
    switch (action.type) {
        case CREATE_GALLERY_REQUEST:
            return {
                loading: true,
            };

        case CREATE_GALLERY_SUCCESS:
            return {
                ...state,
                loading: false,
                success: action.payload.data.success,
                message: action.payload.data.message
            };

        case CREATE_GALLERY_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        case RESET_NEW:
            return {
                loading: false
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};

export const galleryReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_GALLERY_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case DELETE_GALLERY_SUCCESS:
            return {
                ...state,
                loading: false,
                isDeleted: action.payload.data.success,
                message: action.payload.data.message
            };

        case DELETE_GALLERY_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case RESET_DELETE:
            return {
                loading: false,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};