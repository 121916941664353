import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CreateTeam from './CreateTeam';
import DashboardBreadcrumb from '../dashboardBreadcrumb/DashboardBreadcrumb';
import Loader from '../../../components/loader/Loader';
import Conformation from '../conformation/Conformation';
import Notify from '../notify/Notify';
import { clearErrors, deleteTeam, getTeam, resetDelete } from '../../../state/actions/teamAction';
import { useNavigate } from 'react-router-dom';

const TeamIndex = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { loading, teams } = useSelector(state => state.teams);

    const { loading: deleteLoading, error: deleteError, isDeleted, message: deleteMessage } = useSelector(state => state.team);

    const [isMessage, setIsMessage] = useState({
        isThereMessage: false,
        messageType: "",
        message: ""
    });

    const closeMessage = () => {
        return setIsMessage({
            isThereMessage: false,
            messageType: "",
            message: ""
        });
    }

    const [confirmBox, setConfirmBox] = useState(false);
    const [cid, setCid] = useState(null);

    const proceedToConfirm = (id) => {
        setCid(id);
        setConfirmBox(true);
    }

    const proceedToDelete = () => {
        setConfirmBox(false);
        return dispatch(deleteTeam(cid));
    }

    const sendMeToUpdate = (id) => {
        return navigate(`/dashboard/updateTeam/${id}`);
    }

    useEffect(() => {
        if (deleteError) {
            setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: deleteError
            });
            dispatch(clearErrors());
        }

        if (isDeleted) {
            setIsMessage({
                isThereMessage: true,
                messageType: "success",
                message: deleteMessage
            });
            dispatch(resetDelete());
        }

        dispatch(getTeam());
    }, [dispatch, isDeleted, deleteError, deleteMessage]);

    return (
        <>
            {loading || deleteLoading ? <Loader /> : <></>}
            <DashboardBreadcrumb title="Manage Team Members" />
            {
                confirmBox
                    ?
                    <Conformation setConfirmBox={setConfirmBox} proceedFurther={proceedToDelete} message="Hy...Do you want to remove?" btnName="DELETE" />
                    :
                    <></>
            }
            <section className="dashboard-operation-section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="dashboard-data-table">
                                {
                                    isMessage.isThereMessage ? <Notify message={isMessage.message} type={isMessage.messageType} closeMessage={closeMessage} /> : <></>
                                }
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Preview</th>
                                            <th>Name</th>
                                            <th>Designation</th>
                                            <th>Orientation</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            teams && teams.map((data, index) => (
                                                <tr key={index}>
                                                    <td className="table-image">
                                                        <img src={data.image} draggable={false} alt="table" />
                                                    </td>
                                                    <td>{data.name}</td>
                                                    <td>{data.designation}</td>
                                                    <td>{data.orientation}</td>
                                                    <td className="table-action whiteSpaceTrue">
                                                        <button className="update" onClick={()=> sendMeToUpdate(data.id)}>UPDATE</button>
                                                        <button className="delete" onClick={() => proceedToConfirm(data.id)}>DELETE</button>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <CreateTeam />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default TeamIndex;