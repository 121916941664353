import React, { useEffect } from 'react';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import { getMdMessage } from '../../state/actions/mdMessageAction';
import Loader from '../../components/loader/Loader';
import Team from '../../pages/views/Team';
import deco from '../../assets/images/design/purplebg.png';

const AboutSub = () => {
    const dispatch = useDispatch();

    const { loading, mdMessage } = useSelector(state => state.mdMessage);

    useEffect(() => {
        dispatch(getMdMessage());
    }, [dispatch]);

    return (
        <>
            {loading ? <Loader /> : <></>}
            <section className="about-subpage">
                <Breadcrumb title="About Us" />
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="about-desc">
                                <p className="studymate-explain">
                                    StudyMate International Educational Consultancy is your true education partner which is located in Pokhara Metropolitan City, Ward No. 1, Bagar, Kaski, Gandaki Province, Nepal. Your dream of studying abroad only can be possible when you choose the right educational consultancy for the processing. We can be one of the best and right platforms for the interested one. We assist our students to enroll to the top-ranked colleges and universities of different countries like Japan, Australia, Canada, USA, UK, Finland and Hungary as well as we help to choose right programs, colleges, universities and country according to their needs and academic background. We fully help our students from the beginning to the end level throughout the processing. We always try to do something great and different to fulfill our students' dream. We give the best guidelines to our students for their study abroad from the highly qualified, professional, experienced and certified educational counselors in a familiar environment. We provide quality preparation classes of IELTS, PTE and NAT by using modern technology. We are always determined to win the trust of parents and students. If you think about study abroad don't forget to think about your mate i.e. StudyMate International Educational Consultancy Pvt. Ltd.
                                </p>
                                <img src={deco} alt="deco" className="deco_img deco_img1" />
                                <img src={deco} alt="deco" className="deco_img deco_img2" />
                            </div>
                            <div className="why-choose-us">
                                <h3>HIGHLY EXPERIENCED TEAM & HIGH SUCCESS RATE</h3>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="why-choose-point-parent">
                                            <div className="why-choose-point-childs">
                                                <i className="fa fa-user-clock"></i>
                                                <p>DECADE OF EXPERTISE</p>
                                            </div>
                                            <div className="why-choose-point-childs">
                                                <i className="fa fa-graduation-cap"></i>
                                                <p>SCHOLARSHIP ASSISTANCE</p>
                                            </div>
                                        </div>
                                        <div className="why-choose-point-parent">
                                            <div className="why-choose-point-childs">
                                                <i className="fa fa-bars"></i>
                                                <p>STEP BY STEP GUIDANCE</p>
                                            </div>
                                            <div className="why-choose-point-childs">
                                                <i className="fa fa-headphones-alt"></i>
                                                <p>AFTER VISA SUPPORT</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="why-choose-wrapper">
                                            <h2>Why Choose Studymate?</h2>
                                            <div className="break-line"></div>
                                            <p className="studymate-explain">
                                                StudyMate International Educational Consultancy is your true education partner which is located in Pokhara Metropolitan City, Ward No. 1, Bagar, Kaski, Gandaki Province, Nepal. Your dream of studying abroad only can be possible when you choose the right educational consultancy for the processing. We can be one of the best and right platforms for the interested one. We assist our students to enroll to the top-ranked colleges and universities of different countries like Japan, Australia, Canada, USA, UK, Finland and Hungary as well as we help to choose right programs, colleges, universities and country according to their needs and academic background.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="message-from-md-sub">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="message-from-md-sub-parent">
                                    <div className="message-from-md-sub-childs">
                                        <img src={mdMessage && mdMessage.image} alt="studymate-md" />
                                    </div>
                                    <div className="message-from-md-sub-childs">
                                        <h3>MESSAGE FROM MANAGING DIRECTOR</h3>
                                        <div className="detail-container-only">
                                            <div dangerouslySetInnerHTML={{ __html: mdMessage && mdMessage.message }} />
                                        </div>
                                        <div className="line-break"></div>
                                        <h2>{mdMessage && mdMessage.designation}</h2>
                                        <h4>- {mdMessage && mdMessage.name}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Team />
            </section>
        </>
    )
}

export default AboutSub