export const GET_MD_MESSAGE_REQUEST = "GET_MD_MESSAGE_REQUEST";
export const GET_MD_MESSAGE_SUCCESS = "GET_MD_MESSAGE_SUCCESS";
export const GET_MD_MESSAGE_FAIL = "GET_MD_MESSAGE_FAIL";

export const UPDATE_MD_MESSAGE_REQUEST = "UPDATE_MD_MESSAGE_REQUEST";
export const UPDATE_MD_MESSAGE_SUCCESS = "UPDATE_MD_MESSAGE_SUCCESS";
export const UPDATE_MD_MESSAGE_FAIL = "UPDATE_MD_MESSAGE_FAIL";

export const RESET_UPDATE = "RESET_UPDATE";

export const CLEAR_ERRORS = "CLEAR_ERRORS";