import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Notify from '../notify/Notify';
import img_pre from '../../../assets/images/img-pre.png';
import JoditEditor from 'jodit-react';
import Loader from '../../../components/loader/Loader';
import { clearErrors, detailService, resetDetail, resetUpdate, updateService } from '../../../state/actions/serviceAction';

const UpdateService = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();

    const { loading, service, error } = useSelector(state => state.serviceDetail);
    const { loading: updateLoading, isUpdated, error: updateError } = useSelector(state => state.service);

    const [isMessage, setIsMessage] = useState({
        isThereMessage: false,
        messageType: "",
        message: ""
    });

    const closeMessage = () => {
        return setIsMessage({
            isThereMessage: false,
            messageType: "",
            message: ""
        });
    }

    const editor = useRef(null);
    const [content, setContent] = useState("");
    const [serviceVal, setServiveVal] = useState({
        title: "",
        orientation: "",
        category: ""
    });
    console.log(serviceVal)

    const [file, setFile] = useState(null);
    const [oldFile, setOldFile] = useState(null);

    const onChangeHandler = (e) => {
        setServiveVal({ ...serviceVal, [e.target.name]: e.target.value });
    }

    const submitHandller = (e) => {
        e.preventDefault();

        if (!serviceVal.title || !content || !serviceVal.orientation || !serviceVal.category) {
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: "Mandatory field is missing!"
            });
        };

        if (file) {
            if ((file.size / 1024) >= 500) {
                return setIsMessage({
                    isThereMessage: true,
                    messageType: "danger",
                    message: "File size should be less than '500kb'!"
                });
            };
        }

        const myForm = new FormData();
        myForm.append("title", serviceVal.title);
        myForm.append("orientation", serviceVal.orientation);
        myForm.append("category", serviceVal.category);
        myForm.append("description", content);

        if (file) {
            myForm.append("image", file);
        }

        dispatch(updateService(id, myForm));
    }

    useEffect(() => {
        dispatch(detailService(id));
        setServiveVal({
            title: service.title,
            orientation: service.orientation,
            category: service.category
        });
        setContent(service.description);
        setOldFile(service.image);

        if (isUpdated) {
            setIsMessage({
                isThereMessage: true,
                messageType: "success",
                message: isUpdated.message
            });
            dispatch(resetDetail());
            dispatch(resetUpdate());
            navigate("/dashboard/manageService");
        }

        if (error) {
            setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: error
            });
            dispatch(clearErrors());
        }

        if (updateError) {
            setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: updateError
            });
            dispatch(clearErrors());
        }
    }, [
        dispatch,
        error,
        updateError,
        navigate,
        isUpdated,
        id,
        service.title,
        service.orientation,
        service.category,
        service.description,
        service.image
    ]);

    return (
        <>
            {loading || updateLoading ? <Loader /> : <></>}
            <div className="dashboard-forms">
                <h4>UPDATE SERVICE</h4>
                {
                    isMessage.isThereMessage ? <Notify message={isMessage.message} type={isMessage.messageType} closeMessage={closeMessage} /> : <></>
                }
                <form>
                    <div className="input-group">
                        <label className="dashboard-label">Title</label>
                        <input
                            type="text"
                            className="dashboard-input"
                            placeholder="Enter Title"
                            name="title"
                            value={serviceVal.title}
                            onChange={(e) => onChangeHandler(e)}
                        />
                    </div>
                    <div className="input-group">
                        <label className="dashboard-label">Orientation <span>(Alert! - This will define whiic data come first)</span></label>
                        <input
                            type="number"
                            className="dashboard-input"
                            placeholder="Enter Orientation"
                            name="orientation"
                            value={serviceVal.orientation}
                            onChange={(e) => onChangeHandler(e)}
                        />
                    </div>
                    <div className="input-group">
                        <label className="dashboard-label">Category</label>
                        <select name="category" value={serviceVal.category} onChange={(e) => onChangeHandler(e)} className="dashboard-input">
                            <option>- Select Category -</option>
                            <option value="Study Abroad">Study Abroad</option>
                            <option value="Preparation Classes">Preparation Classes</option>
                            <option value="Language Classes">Language Classes</option>
                        </select>
                    </div>
                    <div className="input-group">
                        <label className="dashboard-label">Description</label>
                        <JoditEditor
                            ref={editor}
                            value={content}
                            tabIndex={1}
                            onChange={newContent => setContent(newContent)}
                        />
                    </div>
                    <div className="input-group-type-file">
                        <label className="dashboard-label">Thumbnail <span>(Alert! - File size should be less then "500kb")</span></label>
                        <input
                            type="file"
                            className="dashboard-input-type-file"
                            accept="image/*"
                            onChange={(e) => setFile(e.target.files[0])}
                        />
                    </div>
                    {
                        file && file
                            ?
                            <div className="form-img-preview">
                                <img src={URL.createObjectURL(file)} draggable={false} alt="preview" />
                                <div className="file-remove-btn">
                                    <button onClick={() => setFile(null)}>REMOVE <i className="fa fa-times"></i></button>
                                </div>
                            </div>
                            :
                            <div className="form-img-preview">
                                <img src={img_pre} draggable={false} alt="preview" />
                            </div>
                    }
                    {
                        oldFile && oldFile
                            ?
                            <div className="form-img-preview">
                                <img src={oldFile} draggable={false} alt="preview" />
                            </div>
                            :
                            <></>
                    }
                    <div className="dashboard-submit-btn100">
                        <button onClick={submitHandller}>UPDATE</button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default UpdateService