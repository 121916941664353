import {
    GET_MD_MESSAGE_REQUEST,
    GET_MD_MESSAGE_SUCCESS,
    GET_MD_MESSAGE_FAIL,
    UPDATE_MD_MESSAGE_REQUEST,
    UPDATE_MD_MESSAGE_SUCCESS,
    UPDATE_MD_MESSAGE_FAIL,
    RESET_UPDATE,
    CLEAR_ERRORS,
} from '../constants/mdMessageConstant';

export const mdMessageReducer = (state = { mdMessage: {} }, action) => {
    switch (action.type) {
        case GET_MD_MESSAGE_REQUEST:
            return {
                loading: true,
                mdMessage: {}
            };

        case GET_MD_MESSAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                mdMessage: action.payload.data.message
            };

        case GET_MD_MESSAGE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};

export const mdMsgReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_MD_MESSAGE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case UPDATE_MD_MESSAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: action.payload.data.success,
                message: action.payload.data.message
            };

        case UPDATE_MD_MESSAGE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case RESET_UPDATE:
            return {
                loading: false
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};
